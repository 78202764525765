import {Option} from "./Option";

export class Answer {
    type: string;
    options?: Option[];
    value?: string | number | string[];
    other?: boolean;
    multiValued?: boolean;
    gauge?: boolean;
    unit?: string;
    date?: boolean;
    time?: boolean;
    dateThenTime?: boolean;
    title?: string;

    constructor(type: string) {
        this.type = type;
    }
}
