import {BaseController} from "./BaseController";
import {News} from "../models/News";
import {ApiNewsList} from "../models/api/ApiNews";
import {Constants} from "../utils/Constants";

export class NewsController extends BaseController {
    getList(): Promise<News[]> {
        let url = "jsonapi/node/news";

        let language = localStorage.getItem(Constants.KEY_LANGUAGE);
        if (language && language !== "fr") {
            url = language + "/" + url;
        }

        return super.get(url).then(res => {
            return (res as ApiNewsList).data.map(n => new News(n.attributes.title, n.attributes.body.processed));
        });
    }
}