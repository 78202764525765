import {Question} from "../../../models/Question";
import "./QuestionYesNoStyles.scss";
import { Constants } from "../../../utils/Constants";
import { dataTraduction } from "../../../utils/DataTraduction";

interface IProps {
    question: Question;
    onOptionClick: any;
}

export const QuestionYesNoView = (props: IProps) => {
    const isBlindModeOn: string = localStorage.getItem(Constants.BLIND_MODE)!;
   
    let picture;

    if (props.question.picture !== undefined && isBlindModeOn === 'false') {
        picture = (
            <div className={"question-picture"}>
                <img src={props.question.picture} alt={props.question.title}/>
            </div>
        )
    }

    let radios = [];

    if (props.question.answer?.options) {
        let no = props.question.answer.options.find(o => o.id === "n");
        let traductionYesNo = dataTraduction.find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data!;
        if (no) {
            let src = process.env.PUBLIC_URL + "/pictos/" + (props.question.answer.value === "n" ? "cross-select.svg" : "cross-unselect.svg");
            radios.push(
                <div key={'radio-no'} className={"radio"}>
                    <img key={"n"} src={src} onClick={() => props.onOptionClick("n")} alt={"Non"}/>
                    {/* <p>{no.title}</p> */}
                    {isBlindModeOn === 'false' && 
                     <p>{traductionYesNo.no}</p>
                    }
                </div>
            )
        }

        let yes = props.question.answer.options.find(o => o.id === "o");
        if (yes) {
            let src = process.env.PUBLIC_URL + "/pictos/" + (props.question.answer.value === "o" ? "v-select.svg" : "v-unselect.svg");
            radios.push(
                <div key={'radio-yes'} className={"radio"}>
                    <img key={"o"} src={src} onClick={() => props.onOptionClick("o")} alt={"Oui"}/>
                    {/* <p>{yes.title}</p> */}
                    {isBlindModeOn === 'false' && 
                     <p>{traductionYesNo.yes}</p>
                    }
                </div>
            )
        }
    }

    return (
        <div className={"question-content question-yes-no"}>
            {picture}
            <div className={"yes-no"}>
                {radios}
            </div>
        </div>
    )
}
