import React, { Component } from 'react';
import View from './QuestionClickableImageView';
import { Question } from "../../../models/Question";
import { Option } from "../../../models/Option";
import { ClickableImage } from "../../../models/ClickableImage";


export type Area = {
    id: string;
    shape: 'rect' | 'circle' | 'poly';
    coords: number[];
    preFillColor?: string;
    index: number;
    next: string;
};

export type ImageAreas = {
    name: string;
    areas: Area[];
};

export type ImageMapper = {
    imageSrc: string;
    map: ImageAreas;
}

interface PageProps {
    question: Question
    setQuestion: any;
}

interface PageState {
    clickedAreas: string[];
    listImagesMapper: ImageMapper[];
    listAreas: any;
}

class QuestionClickableImagePage extends Component<PageProps, PageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            clickedAreas: [],
            listImagesMapper: [],
            listAreas: []
        };
    }

    componentDidMount() {
        let el: HTMLElement = document.createElement("div");
        el.innerHTML = (this.props.question.answer as ClickableImage).map;
        let tmp: ImageMapper[] = [];

        if (el.children.length) {
            for (let i = 0; i < el.children.length; i += 2) {
                let imageSrc: string = el.children[i].attributes.getNamedItem("src")?.value!;
                let imageName: string = el.children[i + 1].attributes.getNamedItem("name")?.value!;
                let areas: any[] = [];

                for (let htmlArea of Array.prototype.slice.call(el.children[i + 1].children) as HTMLAreaElement[]) {
                    let area = {} as any;
                    area.id = htmlArea.title;
                    area.next = htmlArea.dataset.next ? htmlArea.dataset.next : this.props.question.next;
                    area.coords = htmlArea.coords.split(",");
                    area.shape = htmlArea.shape;
                    area.fillColor = "#590DE1";
                    area.index = i;

                    if (Array.isArray(this.props.question.answer?.value)) {
                        this.props.question.answer?.value.forEach((e: string, i: number) => {
                            if (i !== 0) {
                                if (area.id === e) {
                                    this.setState(prevState => ({ clickedAreas: [...prevState.clickedAreas, area.next.concat("&&&", area.id)] }));
                                }
                            }
                        });
                    }
                    areas.push(area);
                }

                tmp.push({
                    imageSrc: imageSrc,
                    map: {
                        name: imageName,
                        areas: areas
                    }
                })
            };
            this.setState({ listImagesMapper: tmp });
        }
    }

    onAreaClick = (area: any, clickedAreasFromView: string[]) => {
        this.setState({
            clickedAreas: clickedAreasFromView
        });

        let question = this.props.question;
        let separatedArray: string[] = [];

        if (question.answer) {
            if (clickedAreasFromView.length > 0) {
                clickedAreasFromView.forEach((e: string, i: number) => {
                    if (i === 0) {
                        separatedArray = e.split('&&&');
                    } else {
                        separatedArray.push(e.split('&&&')[1]);
                    }
                });
                question.answer.value = separatedArray as string[];
            }
            else {
                question.answer.value = undefined;
            }
            this.props.setQuestion(question);
        }
    };

    componentWillUnmount() {
        this.setState({ clickedAreas: [] });
    }

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {this.state.listImagesMapper?.map((image: any, index: number) =>
                    <div style={{ zoom: this.state.listImagesMapper.length !== 1 ? 0.15 : 'unset' }}>
                        <View
                            imageMapper={image}
                            onAreaClick={this.onAreaClick}
                            clickedAreas={this.state.clickedAreas}
                        ></View>

                    </div>
                )}
            </div>
        );
    }
}

export default QuestionClickableImagePage;
