import {Component} from "react";
import {Question} from "../../../models/Question";
import {QuestionNoResponseView} from "./QuestionNoResponseView";

interface IProps {
    question: Question;
}

export class QuestionNoResponsePage extends Component<IProps> {
    render() {
        return <QuestionNoResponseView question={this.props.question}/>
    }
}