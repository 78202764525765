import { Question } from "../../../models/Question";
import "./QuestionNumberStyles.scss";
import { Constants } from "../../../utils/Constants";

interface IProps {
    question: Question;
    onMinusButtonClick: any;
    onPlusButtonClick: any
}
export const QuestionNumberView = (props: IProps) => {
    const isBlindModeOn: string = localStorage.getItem(Constants.BLIND_MODE)!;
    let picture;
    let value = props.question.answer?.value;

    if (props.question.picture && isBlindModeOn === 'false') {
        if (value) {
            let img: any = [];
            for (let i = 0; i < value; i++) {
                img.push(
                    <img
                        className={`question-picture-img ${value >= 10 && "question-picture-img--multi-10"}`}
                        src={props.question.picture}
                        alt={props.question.title} />)
            }
            picture = (
                <div className={"question-picture"}>
                    {img}
                </div>
            )
        } else if (value === 0) {
            picture = null;
        } else {
            picture = (
                <div className={"question-picture"}>
                    <img src={props.question.picture} alt={props.question.title} />
                </div>
            )
        }
    }

    return (
        <div className={"question-content question-number"}>
            {picture}
            <div className={"number"}>
                <button onClick={() => props.onMinusButtonClick()}>−</button>
                <div>{props.question.answer?.value}</div>
                <button onClick={() => props.onPlusButtonClick()}>+</button>
            </div>
            <div className={"unit"}>
                {props.question.answer?.unit}
            </div>
        </div>
    )
}