import { useState, useEffect } from 'react';

type Style = {
  color: string;
  backgroundColor: string;
  fontSize: string;
};

const useCustomStyle = (styleName: string, { color, backgroundColor, fontSize }: Style) => {
  const [style, setStyle] = useState<Style>();

  const handleClick = () => {
    if (style === undefined) {
      const newStyle = {
        color: color,
        backgroundColor: backgroundColor,
        fontSize: fontSize
      };
      setStyle(newStyle);
      window.localStorage.setItem(styleName, JSON.stringify(newStyle));
    } else {
      setStyle(undefined);
      window.localStorage.setItem(styleName, JSON.stringify(''));
    };
  };

  useEffect(() => {
    let styleHeaderStored = JSON.parse(window.localStorage.getItem(styleName)!);
    styleHeaderStored ? setStyle(styleHeaderStored) : setStyle(undefined);

  }, [])
  
  return { style, handleClick };
}

export default useCustomStyle;