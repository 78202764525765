import "./LegalMentionsStyles.scss";
import { Constants } from "../../utils/Constants";
// @ts-ignore
import Popup from "../popup/Popup";
import React, { useState } from 'react';
import { dataTraduction } from "../../utils/DataTraduction";

interface IProps {
    title: string;
    content: string;
    onDeclineClick: any;
    onAcceptClick: any;
}


export const LegalMentionsView = (props: IProps) => {

    const inputElRGPD = React.useRef<HTMLInputElement>(null);
    const inputElCGU = React.useRef<HTMLInputElement>(null);

    const [checkedRGPD, setCheckedRGPD] = useState(false);
    const [checkedCGU, setCheckedCGU] = useState(false);

    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const handlerOpenPopup = () => {
        setOpenPopup(!openPopup);
    };

    const onBtnClick = () => {
        !checkedCGU && inputElCGU.current?.focus();
        !checkedRGPD && inputElRGPD.current?.focus();
    };

    const handleChange = (typeChecked: string) => {
        typeChecked === "RGPD" ? setCheckedRGPD(!checkedRGPD) : setCheckedCGU(!checkedCGU);
    };

    return (
        <div className={"page"}>
            <div className={"lm"}>
                <div className={"lm-header"}>
                    {props.title}
                </div>
                <div className={"lm-content"} >
                    <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
                    <div className={`lm-checkbox`}>
                        <div style={{ display: 'flex', gap: '2%', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                checked={checkedRGPD}
                                onChange={() => handleChange("RGPD")}
                                ref={inputElRGPD}
                                style={{ minWidth: '20px' }}
                                // autoFocus
                                alt={"Accepter"} />
                            <div>
                                {dataTraduction
                                    .find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data.RGPD
                                    .map((text, i) =>
                                        <div key={i}>{text}</div>
                                    )}
                            </div>
                        </div>

                        <br />
                        <div style={{ display: 'flex', gap: '2%', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                checked={checkedCGU}
                                onChange={() => handleChange("CGU")}
                                ref={inputElCGU}
                                style={{ minWidth: '20px' }}
                                // autoFocus
                                alt={"Accepter"} />
                            <div>
                                {dataTraduction
                                    .find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data.CGU
                                    .map((text, i) =>
                                        <div key={i}>{text}</div>
                                    )}
                                <div style={{ textDecoration: 'underline', color: '#3366CC' }} onClick={handlerOpenPopup}>(lien hypertexte)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"lm-footer"}>
                    <img src={process.env.PUBLIC_URL + "/pictos/cross-select.svg"} onClick={props.onDeclineClick} alt={"Décliner"} />
                    <img src={process.env.PUBLIC_URL + "/pictos/v-select.svg"} onClick={checkedRGPD && checkedCGU ? props.onAcceptClick : onBtnClick} alt={"Accepter"} />
                </div>
            </div>
            {openPopup && <Popup closePopup={handlerOpenPopup}></Popup>}
        </div>
    )
}
