const ENV = {
    dev: {
        apiUrl: "https://api.martiapp.fr/"
    },
    prod: {
        apiUrl: "https://api.martiapp.fr/"
    }
};

const getEnv = ()  => {
    if (process.env.NODE_ENV === "production") {
        return ENV.prod;
    } else {
        return ENV.dev;
    }
};

export default getEnv;
