import {BaseController} from "./BaseController";
import {TaxonomyTerm} from "../models/TaxonomyTerm";
import {ApiTaxonomy} from "../models/api/ApiTaxonomy";
import getEnv from "../environment";

export class TaxonomyController extends BaseController {
    getByName(name: string): Promise<TaxonomyTerm[]> {
        return this.get("jsonapi/taxonomy_term/" + name).then(res => {
            return (res as ApiTaxonomy).data.map(t => new TaxonomyTerm(t.attributes.name, t.attributes.description?.processed));
        });
    }

    async getTranslated(name: string, uuid: string, language: string): Promise<TaxonomyTerm> {
        let url = `jsonapi/taxonomy_term/${name}/${uuid}`;
        if (language !== 'fr') {
            url = language + "/" + url;
        }

        return this.get(url).then(res => {
            return new TaxonomyTerm(res.data.attributes.name, res.data.attributes.description?.processed);
        });
    }

    getFlags(): Promise<TaxonomyTerm[]> {
        return this.getByName("flags").then(flags => {
            for (let flag of flags) {
                let el = new DOMParser().parseFromString(flag.value,"text/xml");
                flag.value = getEnv().apiUrl + el.getElementsByTagName("img")[0].getAttribute("src") as string;
            }
            return flags;
        });
    }
}
