import { Component } from "react";
import { LanguageController } from "../../controllers/LanguageController";
import { LanguageView } from "./LanguageView";
import { Constants } from "../../utils/Constants";
import { LoadingView } from "../loading/LoadingView";
import { TaxonomyController } from "../../controllers/TaxonomyController";
import { Language } from "../../models/Language";
import { compareStrings } from "../../utils/Utils";
import { ServiceController } from "../../controllers/ServiceController";

interface IState {
  languages: Language[];
  firstQuestion: string;
  translateIdk: string;
  translateButtonText: string;
  translateBackSurvey: string;
  isBlindModeOn: string;
}

export class LanguagePage extends Component<{}, IState> {
  languageController = new LanguageController();
  taxonomyController = new TaxonomyController();
  serviceController = new ServiceController();

  componentDidMount() {
    if (localStorage.getItem(Constants.BLIND_MODE) === null) { localStorage.setItem(Constants.BLIND_MODE, 'false') };
    Promise.all([this.taxonomyController.getFlags(), this.languageController.get()]).then(res => {
      let flags = res[0];
      let languages = Object.keys(res[1].data.languages).map(key => new Language(key, res[1].data.languages[key]));


      flags.forEach(flag => {
        let language = languages.find(l => l.key === flag.key);
        if (language) {
          language.flagUrl = flag.value;
        }
      });

      this.setState({
        languages,
        firstQuestion: res[1].data.firstquestion,
        translateIdk: res[1].data["translate-idk"],
        translateButtonText: res[1].data["translate-button-text"],
        translateBackSurvey: res[1].data["translate-back-survey"],
        isBlindModeOn: 'false',
      });
    });

    if (!localStorage.getItem(Constants.KEY_FIRST_QUESTION)) {
      let firstQuestionId = "5ce09476-b23a-404a-859e-17c5bea91080";
      this.serviceController.getServices().then(res => {
        if (res) {
          let service = res.find((s: any) => compareStrings(window.location.pathname, s.name));
          if (service) {
            firstQuestionId = service.firstQuestionId;
          }
        } 
        localStorage.setItem(Constants.KEY_FIRST_QUESTION, firstQuestionId);
      })
    };
  }

  render() {
    if (this.state === null || this.state.languages === null) {
      return <LoadingView />
    } else {
      return <LanguageView languages={this.state.languages}
        handleClickLanguage={this.handleClickLanguage.bind(this)}
        isBlindModeOn={this.state.isBlindModeOn}
        toggleBlindMode={this.toggleBlindMode.bind(this)} />
    }
  }

  private async handleClickLanguage(language_key: string, language_name: string) {
    localStorage.setItem(Constants.KEY_LANGUAGE, language_key);
    localStorage.setItem(Constants.NAME_LANGUAGE, language_name);
    localStorage.setItem(Constants.KEY_START, new Date().toLocaleString());

    let [resIdk, resButtonText, resBackSurvey] = await Promise.all([
      this.taxonomyController.getTranslated(Constants.TAXONOMY_TEXT_TO_TRANSLATE, this.state.translateIdk, language_key),
      this.taxonomyController.getTranslated(Constants.TAXONOMY_TEXT_TO_TRANSLATE, this.state.translateButtonText, language_key),
      this.taxonomyController.getTranslated(Constants.TAXONOMY_TEXT_TO_TRANSLATE, this.state.translateBackSurvey, language_key)
    ]);

    localStorage.setItem(Constants.KEY_TRANSLATE_IDK, resIdk.key);
    localStorage.setItem(Constants.KEY_TRANSLATE_BUTTON_TEXT, resButtonText.key);
    localStorage.setItem(Constants.KEY_TRANSLATE_BACK_SURVEY, resBackSurvey.key);

    window.location.href = "legal-mentions";
  }

  private toggleBlindMode() {
    if (this.state.isBlindModeOn === 'true') {
      this.setState({ isBlindModeOn: 'false' })
      localStorage.setItem(Constants.BLIND_MODE, 'false');
    } else {
      this.setState({ isBlindModeOn: 'true' });
      localStorage.setItem(Constants.BLIND_MODE, 'true');
    }
  }
}
