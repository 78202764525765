import {Question} from "../../../models/Question";
import {Component} from "react";
import {QuestionTimeView} from "./QuestionTimeView";
import moment from "moment";

interface IProps {
  question: Question;
  setQuestion: any;
}

interface IState {
  date?: string;
  time?: string;
}

export class QuestionTimePage extends Component<IProps, IState> {
  state = {
    date: undefined,
    time: undefined
  }

  render() {
    let showDate = this.props.question.answer?.date || this.props.question.answer?.dateThenTime;
    let showTime = this.props.question.answer?.time ||
      (this.props.question.answer?.dateThenTime && this.state.date === moment().format("DD/MM/YYYY"));

    return <QuestionTimeView question={this.props.question}
                             onChangeDate={this.onChangeDate.bind(this)}
                             onChangeTime={this.onChangeTime.bind(this)}
                             showDate={showDate}
                             showTime={showTime}/>
  }

  private onChangeDate(event: any) {
    this.setState({date: moment(event.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")});
    let answer = this.props.question.answer;
    if (answer) {
      answer.value = event.target.value;
      if (this.state.time !== undefined) {
        answer.value += " " + this.state.time;
      }
      this.props.setQuestion(this.props.question);
    }
  }

  private onChangeTime(event: any) {
    this.setState({time: event.target.value});
    let answer = this.props.question.answer;
    if (answer) {
      answer.value = event.target.value;
      if (this.state.date !== undefined) {
        answer.value = this.state.date + " " + answer.value;
      }
      this.props.setQuestion(this.props.question);
    }
  }
}
