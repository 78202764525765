import {BaseController} from "./BaseController";
import {ApiMedia} from "../models/api/ApiMedia";
import getEnv from "../environment";
import {ApiFile} from "../models/api/ApiFile";
import {Picture} from "../models/Picture";

export class MediaController extends BaseController {
    get(url: string): Promise<Picture> {
        return super.get(url).then(async res => {
            let picture = new Picture("");
            let mediaImage = (res as ApiMedia).data.relationships.field_media_image;
            await super.get(mediaImage.links.related.href).then(res2 => {
                picture = new Picture(getEnv().apiUrl + (res2 as ApiFile).data.attributes.uri.url);
                picture.alt = mediaImage.data.meta.alt;
            });

            return picture;
        });
    }
}