import {BaseController} from "./BaseController";
import {Question} from "../models/Question";
import {AnswerController} from "./AnswerController";
import {ApiQuestion} from "../models/api/ApiQuestion";
import {Constants} from "../utils/Constants";
import {MediaController} from "./MediaController";

export class QuestionController extends BaseController {
    answerController = new AnswerController();
    mediaController = new MediaController();

    get(url: string): Promise<Question> {
        return super.get(url).then(async res => {
            return await this.convertToQuestion(res);
        });
    }

    getById(id: string): Promise<Question> {
        let url = "jsonapi/node/question/" + id;

        let language = localStorage.getItem(Constants.KEY_LANGUAGE);
        if (language && language !== "fr") {
            url = language + "/" + url;
        }

        return super.get(url).then(async res => {
            return await this.convertToQuestion(res);
        });
    }

    private async convertToQuestion(res: ApiQuestion): Promise<Question> {
        let data = res.data;
        let question = new Question(data.id, data.attributes.title);

        if (data.relationships.field_question_default_next.data) {
            question.next = data.relationships.field_question_default_next.data.id;
        }

        if (data.relationships.field_reponse) {
            await this.answerController.getByUrl(data.relationships.field_reponse.links.related.href).then(value => {
                question.answer = value;
            });
        }

        if (data.relationships.field_picto?.data) {
            await this.mediaController.get(data.relationships.field_picto.links.related.href).then(res2 => {
                question.picture = res2.url;
                question.pictureAlt = res2.alt;
            });
        }

        return question;
    }
}
