import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {

  return (
    <div className="privacy-policy">
      <div className="privacy-policy-box">
        <div className="privacy-policy-box__title">
          <div className="title-1">POLITIQUE DE CONFIDENTIALITÉ DE MARTI</div>
          <div className="title-2">(Dernière mise à jour : janvier 2023)</div>
        </div>
        <div className="privacy-policy-box__definitions">
          <div className="definitions-title">DÉFINITIONS</div>
          <div className="definition-text">
            <p><span className="text-bold">« Données Personnelles »</span> : toute information se rapportant à une personne physique identifiée ou
              identifiable telle que définie par le Règlement (UE) n°2016/679 du 27 avril 2016 et toute autre
              réglementation équivalente ultérieure.
            </p>
            <p><span className="text-bold">« Lois Applicables en matière de protection des Données » ou « Loi(s) Applicable(s) » </span>
              : désigne le Règlement (UE) 2016/679 du 27 avril 2016 (applicable depuis le 25 mai 2018), la loi n°78-17 du 6
              janvier 1978 relative à l’informatique, aux fichiers et aux libertés modifiée par la loi n°2004-801 du 6
              août 2004 relative au traitement informatisé des Données Personnelles, par la loi pour une République
              numérique n°2016-1321 du 8 octobre 2016, la loi n°2018-493 du 20 juin 2018 relative à la protection
              des données personnelles et tout autre réglementation équivalente ultérieure, et/ou toute loi applicable
              ou réglementation applicable et en vigueur relative à la protection des Données.
            </p>
            <p><span className="text-bold">« Pays Tiers » </span>: désigne tout pays n’appartenant pas à l’Union Européenne et ne disposant pas d’une
              législation adéquate concernant le Traitement des Données Personnelles tel que décidé par la
              Commission Européenne.
            </p>
            <p><span className="text-bold">« Responsable de Traitement » </span>Désigne tout établissement hospitalier qui souscrit à un abonnement donnant accès à la solution éditée
              par MARTI accessible en mode SaaS.
            </p>
            <p><span className="text-bold">« Traitement » </span> : toute opération ou ensemble d’opérations effectuées ou non à l’aide de procédés
              automatisés et appliquées à des Données Personnelles ou des ensembles de Données Personnelles ,
              comme par exemple, la collecte, l’enregistrement, l’organisation, la structuration, la conservation, la
              communication par transmission, la diffusion, l’extraction, la consultation de Données Personnelles et
              défini par le Règlement (UE) n°2016/679 du 27 avril 2016 et toute autre réglementation équivalente
              ultérieure.
            </p>
            <p><span className="text-bold">« Sous-traitant »</span><br />
              MARTI – société par actions simplifiée domiciliée au 132 Cours Albert Thomas 69008 LYON,
              enregistrée au Registre du commerce et des sociétés de LYON sous le numéro 918 027 632 pour la
              solution MARTI.
            </p>
            <p><span className="text-bold">« Sous-traitant ultérieur » </span>: Désigne le tiers qui traite
              les Données Personnelles pour le Sous-traitant.
            </p>
            <p><span className="text-bold">« Utilisateur Patient » </span>: désigne toute personne patiente de l’établissement hospitalier client qui accède
              à la Solution par le biais de son support personnel (téléphone portable notamment) ou de la tablette ou
              poste de travail mis à disposition par l’établissement hospitalier.
            </p>
            <p><span className="text-bold">« Utilisateur Soignant » </span>désigne tout soignant et plus largement le personnel hospitalier de
              l’établissement hospitalier client qui utilise la Solution et les services qui y sont disponibles.
            </p>
          </div>
        </div>
        ❖


        <div className="privacy-policy-box__content">

          <p>La présente Politique de Confidentialité s’applique à la solution éditée par MARTI (ci-après la
            « Société »), identifiée comme Sous-traitant au paragraphe DÉFINITIONS.</p>
          <p>Il fournit aux Utilisateurs la liste des Traitements pour lesquels il agit en qualité de Sous-traitant du
            Responsable de Traitement.</p>
          <p>Le Sous-traitant s’engage à respecter les Lois applicables dans le cadre du Traitement des Données
            Personnelles effectué sur instruction du Responsable de Traitement sur la solution qu’il édite (ci-après
            désignée la « Solution »).</p>
          <p>Les Données Personnelles recueillies via la Solution sont traitées de manière licite, loyale et
            transparente.</p>
          <p>Les Données Personnelles collectées sont adéquates, pertinentes et limitées à ce qui est strictement
            nécessaire au regard des finalités de Traitement.</p>

          {/* --- Part 1 --- */}
          <div className="item">
            <div className="item-title bold">1. Finalités des Traitements des Données Personnelles</div>

            {/* --- Part 1 a --- */}
            <div className="item-subtitle bold">a. Traitement n°1 : le Traitement a pour finalité la gestion des comptes Utilisateurs Soignants.</div>

            <div className="item-content">
              <p>Le Sous-traitant collecte les Données Personnelles de l’Utilisateur Soignant de la Solution pour :</p>
              <ol>
                <li>La création des comptes et identifiants des Utilisateurs Soignants ;</li>
                <li>La gestion des comptes des Utilisateurs Soignants ;</li>
                <li>L’administration technique des comptes des Utilisateurs Soignants avec les prestataires
                  techniques (hébergement et assistance technique notamment)</li>
              </ol>
              <p className="underline">Base légale du Traitement :</p>
              <p>Article 6 (1) f) du RGPD : le Traitement est nécessaire aux fins d’exécuter le contrat entre le
                Responsable de Traitement, les Personnes concernées et le Sous-Traitant.</p>

              <p className="underline">Base légale du Traitement :</p>
              <p>Données de connexion : adresse mail, mot de passe.</p>

              <p className="underline">Personnes concernées :</p>
              <p>Les Utilisateurs Soignants de la Solution.</p>
              <p>Les Données Personnelles collectées pour la réalisation de cette finalité sont recueillies de façon
                obligatoire.</p>

              <p className="underline">Prise de décision automatisée :</p>
              <p>Le Traitement ne prévoit pas de prise de décision automatisée.</p>
            </div>

            {/* --- Part 1 b --- */}
            <div className="item-subtitle bold">b. Traitement n°2 : Le Traitement a pour finalité de répondre à la demande d’un Utilisateur
              Soignant formulée via l’onglet « Contact » de la Solution</div>

            <div className="item-content">
              <p>Il permet de :</p>
              <p>- Répondre à une sollicitation adressée directement via le formulaire de contact disponible sur la Solution</p>

              <p className="underline">Base légale du Traitement :</p>
              <p>Article 6 (1) f) du RGPD : le traitement est nécessaire à l’exécution du Contrat signé entre le
                Responsable de Traitement et le Sous-Traitant.</p>

              <p className="underline">Catégories de Données Personnelles traitées :</p>
              <ol>
                <li>Nom, prénom</li>
                <li>Adresse électronique</li>
                <li>Toute information transmise par l’Utilisateur Soignant concerné (champs libre).</li>
              </ol>

              <p className="underline">Personnes concernées :</p>
              <p>Les Utilisateurs Soignants.</p>
              <p>Les Données Personnelles collectées pour la réalisation de cette finalité sont recueillies de façon facultative.</p>

              <p className="underline">Prise de décision automatisée :</p>
              <p>Le Traitement ne prévoit pas de prise de décision automatisée.</p>
            </div>

            {/* --- Part 1 c --- */}
            <div className="item-subtitle bold">c. Traitement n° 3 : Le Traitement a pour finalité la transmission aux Utilisateurs Soignants
              des questionnaires remplis par les Utilisateurs Patients</div>

            <div className="item-content">
              <p>Il permet de :</p>
              <p>- Transmettre les questionnaires aux Utilisateurs Soignants une fois qu’ils ont été remplis par les Utilisateurs Patients.</p>

              <p className="underline">Base légale du Traitement :</p>
              <p>Article 6 (1) a) du RGPD : la Personne Concernée a consenti au Traitement de ses Données Personnelles.</p>

              <p className="underline">Catégories de Données Personnelles traitées :</p>
              <ol>
                <li>Nom, prénom, date de naissance, langue parlée</li>
                <li>Données médicales et sociales (antécédents médicaux, symptômes, situation sociale, etc.)</li>
                <li>Toute information transmise par l’Utilisateur Patient concerné (champs libres)</li>
              </ol>

              <p className="underline">Personnes concernées :</p>
              <p>Les Utilisateurs Patients</p>
              <p>Les Données Personnelles collectées pour la réalisation de cette finalité sont recueillies de façon obligatoire</p>

              <p className="underline">Prise de décision automatisée :</p>
              <p>Le Traitement ne prévoit pas de prise de décision automatisée.</p>
            </div>

            {/* --- Part 1 d --- */}
            <div className="item-subtitle bold">d. Traitement n°4 : Le Traitement a pour finalité la traduction des questionnaires remplis
              par les Utilisateurs Patients</div>

            <div className="item-content">
              <p>Il permet de :</p>
              <p>- Traduire les questionnaires lorsque les Utilisateurs Patients les ont remplis dans une autre langue que le français.</p>

              <p className="underline">Base légale du Traitement :</p>
              <p>Article 6 (1) a) du RGPD : la Personne Concernée a consenti au Traitement de ses Données Personnelles</p>

              <p className="underline">Catégories de Données Personnelles traitées :</p>
              <ol>
                <li>Nom, prénom, date de naissance, langue parlée</li>
                <li>Données médicales et sociales (antécédents médicaux, symptômes, situation sociale, etc.)</li>
                <li>Toute information transmise par l’Utilisateur Patient concerné (champs libres)</li>
              </ol>

              <p className="underline">Personnes concernées :</p>
              <p>Les Utilisateurs Patients</p>
              <p>Les Données Personnelles collectées pour la réalisation de cette finalité sont recueillies de façon obligatoire.</p>

              <p className="underline">Prise de décision automatisée :</p>
              <p>Le Traitement ne prévoit pas de prise de décision automatisée.</p>
            </div>

          </div>

          {/* --- Part 2 --- */}
          <div className="item">
            <div className="item-title bold">2. Durée de conservation des Données Personnelles</div>
            <div className="item-content">
              <p>Le Sous-traitant conservera les informations et Données à caractère personnel pendant la durée
                maximum légale ou règlementaire applicable en fonction de la finalité de Traitement ou sur instruction
                du Responsable de Traitement :</p>
              <ol>
                <li>Les Données Personnelles collectées pour la gestion des comptes Utilisateurs Soignants sont
                  conservées pour une durée maximale trois ans à compter de la résiliation du compte de
                  l’Utilisateur Soignant ;</li>
                <li>Les Données Personnelles collectées suite à une demande formulée sur la Solution sont
                  conservées pour une durée maximale de trois ans à compter du dernier contact émanant de la
                  Personne Concernée ;</li>
                <li>Les Données Personnelles des Utilisateurs Patients collectées dans les questionnaires sont
                  conservées en base active pendant une durée d’un (1) jour et sont archivés au maximum vingt
                  (20) ans à compter de la dernière prise en charge de l’Utilisateur Patient.</li>
              </ol>
            </div>
          </div>

          {/* --- Part 3 --- */}
          <div className="item">
            <div className="item-title bold">3. Engagement du Sous-traitant</div>
            <div className="item-content">
              <p>Le Sous-Traitant s'engage à :</p>
              <ol>
                <li>traiter les Données Personnelles uniquement pour les seules finalités décrites ci-avant,</li>
                <li>traiter les Données Personnelles conformément aux Lois applicables,</li>
                <li>en cas de transfert de Données Personnelles vers un Pays Tiers ou à une organisation internationale, informer au préalable le Responsable de Traitement,</li>
                <li>garantir la confidentialité des Données Personnelles en prenant toute les mesures techniques
                  et organisationnelles appropriées pour (i) empêcher l’accès aux Données Personnelles par des
                  personnes non autorisées, (ii) en effectuant des contrôles d’identité et d’accès via un système
                  d’authentification ainsi qu’une politique de mot de passe, (iii) en optant pour un système de
                  gestion des habilitations et (iiii) des processus et dispositifs permettant de tracer l’ensemble des
                  actions réalisées sur son système d’information et d’effectuer conformément aux Lois
                  applicables, des actions de reporting en cas d’incident impactant les Données Personnelles,</li>
                <li>veiller à ce que les personnes autorisées à traiter les Données Personnelles s’engagent à
                  respecter la confidentialité ou soient soumises à une obligation de confidentialité et reçoivent la
                  formation nécessaire en matière de protection des Données personnelles,</li>
                <li>prendre en compte, s’agissant de ses outils, applications ou services, les principes de protection
                  des Données Personnelles dès la conception,</li>
                <li>effacer, anonymiser ou archiver les Données Personnelles à l’issue de la période de
                  conservation,</li>
              </ol>
              <p>Le Sous-traitant ne sera en aucun cas responsable des incidents de sécurité liés à l’utilisation d’Internet,
                notamment en cas de perte, altération, destruction, divulgation ou accès non autorisé à des données
                ou informations de l’Utilisateur.</p>
            </div>
          </div>

          {/* --- Part 4 --- */}
          <div className="item">
            <div className="item-title bold">4. Destinataires/Sous-traitants ultérieurs/Transferts hors UE</div>
            <div className="item-content">
              <p>Les Données Personnelles sont transmises aux services internes de la Société compétents dans la
                limite de leurs attributions respectives et exclusivement aux fins de réaliser les finalités de la présente
                politique de confidentialité.</p>
              <p>La Société peut faire appel à des Sous-Traitants ultérieurs pour lesquelles elle applique les mêmes
                obligations en matière de protection des données que celles fixées dans le contrat qu’elle signe avec
                tout Responsable de Traitement.</p>
              <p>En cas de transfert de tout ou partie des Données Personnelles objet du Traitement vers un Pays Tiers,
                c’est-à-dire situé en dehors de l’Union Européenne ou ne présentant pas un niveau de protection
                reconnu comme adéquat au sens des Lois applicables, ou vers une organisation internationale, le Soustraitant s’engage à prévoir les garanties appropriées prévues au sein des Lois applicables et à les faire
                respecter par ses Sous-traitants ultérieurs.</p>
              <p>En aucun cas le Sous-traitant ne vend, ni ne loue, ni n’utilise autrement que pour les finalités précisées,
                les Données Personnelles dont il est destinataire. La divulgation des Données Personnelles à des tiers
                n’est réalisée par le Sous-traitant qu’aux fins de l’exécution des finalités et aux tiers ayant la qualité de
                Sous-traitants ultérieurs dans les conditions visées aux présentes.</p>
            </div>

            {/* --- Part 4.1 --- */}
            <div className="item-subtitle">
              <p className="bold"> 4.1. Traitement de Données Personnelles à raison de l’hébergement de la Solution</p>
              <p>Les Données Personnelles seront hébergées dans des serveurs localisés dans l’Union européenne, sur
                les serveurs dédiés à la Société fournis par la société AZNetwork, hébergeur agrée de données de
                santé établi en France.</p>
            </div>

            {/* --- Part 4.2  --- */}
            <div className="item-subtitle">
              <p className="bold"> 4.2. Traitements de Données Personnelles pour l’assistance technique</p>
              <p>Pour les prestations d’assistance technique, la Société a recours à un sous-traitant, la société HODORA
                établie en France. Elle peut être amenée, dans ce cadre, à traiter les Données Personnelles des
                Utilisateurs renseignées dans la Solution.</p>
            </div>

            {/* --- Part 4.3  --- */}
            <div className="item-subtitle">
              <p className="bold"> 4.3. Traitements de Données Personnelles pour la traduction des questionnaires</p>
              <p>Les questionnaires soumis aux Utilisateurs Patients peuvent être remplis dans une langue autre que le
                français. Pour permettre aux Utilisateurs Soignants de comprendre le contenu des questionnaires, la
                Société utilise l’API Tierce qui traduit automatiquement les Contenus écrits dans une langue étrangère.</p>
              <p>Dans ce cadre, les Données Personnelles des Utilisateurs Patients peuvent être transférées en dehors
                de l’Union Européenne par l’API Tierce. Celle-ci dispose alors de mesures de sécurité adéquates
                (notamment des clauses contractuelles types) et, par conséquent, un niveau de protection des Données
                Personnelles équivalent au niveau exigé par la réglementation européenne.</p>
            </div>

          </div>

          {/* --- Part 5 --- */}
          <div className="item">
            <div className="item-title bold">5. Exercice des droits de l’Utilisateur de la Solution et recueil du consentement si nécessaire</div>
            <div className="item-content">
              <p>L’Utilisateur de la Solution donne son consentement à la collecte et au Traitement de ses Données
                Personnelles en cochant les cases appropriées sur la Solution lorsque cela est requis par les Lois
                Applicables.</p>
              <p>L’Utilisateur est informé de l’existence du droit de retirer son consentement à tout moment lorsque le
                Traitement est basé sur cette base légale.</p>
              <p>Les droits suivants sont garantis par le Sous-traitant à l’Utilisateur de la Solution : droit d’accès, de
                rectification, d’effacement et d’opposition, droit à la limitation du Traitement, droit à la portabilité́ des
                données, droit de ne pas faire l’objet d’une décision individuelle automatisée (y compris le profilage)</p>
              <p>L’Utilisateur de la Solution peut obtenir une copie de ses Données Personnelles, sur demande écrite et
                adressé au Sous-traitant ou au Responsable de Traitement.</p>
              <p>En envoyant une demande écrite, et à tout moment, l’Utilisateur de la Solution peut obtenir une
                correction ou une suppression de ses Données Personnelles.</p>
              <p>Toute demande doit être adressée par écrit à l’adresse suivante : dpo@martiapp.f</p>
              <p>Si l’Utilisateur de la Solution considère que la Société ou le Responsable de Traitement n’a pas respecté
                ses obligations en matière de protection des données, il peut adresser une plainte auprès d’une autorité
                nationale de protection des données, en France, la CNIL.</p>
            </div>

          </div>

          {/* --- Part 6 --- */}
          <div className="item">
            <div className="item-title bold">6. Sécurité et confidentialité des Données Personnelles – Notification</div>
            <div className="item-content">
              <p>Le Sous-traitant s’engage à mettre en place :</p>
              <p>(a) des mesures de sécurité physique visant à empêcher l’accès aux Données Personnelles par des
                personnes non autorisées,</p>
              <p>(b) des contrôles d’identité et d’accès via un système d’authentification ainsi qu’une politique de mot de
                passe,</p>
              <p>(c) des processus et dispositifs permettant de tracer l’ensemble des actions réalisées sur son système
                d’information et d’effectuer conformément aux Lois Applicables, des actions de reporting en cas
                d’incident impactant les Données Personnelles.</p>
              <p>Ainsi, en termes de sécurité informatique, le Sous-traitant s’efforce d’appliquer les recommandations
                formulées par la CNIL en la matière : politique de mots de passe doublée d’une seconde
                authentification, modification régulière des mots de passe, etc.</p>
              <p>Lorsque les Lois applicables le prévoient, le Sous-traitant notifie à l’Utilisateur de la Solution, toute
                violation de Données Personnelles présentant un risque élevé pour la vie privée de l’Utilisateur dans un
                délai maximum de 72 heures après en avoir pris connaissance par courrier électronique à l’adresse
                email dont elle dispose.</p>
            </div>

          </div>

          {/* --- Part 7 --- */}
          <div className="item">
            <div className="item-title bold">7. Documentation</div>
            <div className="item-content">
              <p>Le Sous-traitant met en place et conserve la documentation nécessaire pour démontrer le respect de
                toutes ses obligations découlant des Lois applicables.</p>
            </div>

          </div>

          {/* --- Part 8 --- */}
          <div className="item">
            <div className="item-title bold">8.  Mise à jour de la Politique de Confidentialité</div>
            <div className="item-content">
              <p>Le Sous-traitant met à jour lorsque cela est nécessaire la présente Politique de Confidentialité qui reste
                disponible sur la Solution à tout moment.</p>
            </div>

          </div>

          {/* --- Part 9 --- */}
          <div className="item">
            <div className="item-title bold">9. Nous contacter</div>
            <div className="item-content">
              <p>MARTI, 132 Cours Albert Thomas, 69008 LYON</p>
              <p><a href="dpo@martiapp.fr">dpo@martiapp.fr</a></p>
            </div>

          </div>
        </div>
      </div>

    </div>

  );
};

export default PrivacyPolicy;