import "./LanguageStyles.scss";
import FlagIcon from "../../components/flag/FlagComponent";
import {Language} from "../../models/Language";

interface IProps {
    languages: Language[];
    handleClickLanguage: any;
    isBlindModeOn: string;
    toggleBlindMode: () => void;
}

export const LanguageView = (props: IProps) => {
    let languages = [];

    languages.push(
        <div key={'blind-mode'} className={"language blind-mode"}
            style={props.isBlindModeOn === 'true' ? { backgroundColor: '#CCCCFF' } : { backgroundColor: '#F1F1F1' }}
            onClick={() => props.toggleBlindMode()}>
            <p>
                {props.isBlindModeOn === 'true' ? 'Désactiver' : 'Activer'} le mode d'aveugle
            </p>
        </div>
    )
    for (let language of props.languages) {
        let flag;

        if (language.flagUrl) {
            flag = (
                <div className={"flag-wrapper"}>
                    <img src={language.flagUrl} alt={language.key}/>
                </div>
            )
        } else {
            let flagCode = language.key;
            if (flagCode === "en") {
                flagCode = "gb";
            } else if (flagCode.includes("-")) {
                flagCode = flagCode.split("-")[1].toLowerCase();
            }

            flag = <FlagIcon code={flagCode}/>;
        }

        languages.push(
            <div key={language.key} className={"language"} onClick={() => props.handleClickLanguage(language.key, language.name)}>
                {flag}
                <p>
                    {language.name}
                </p>
            </div>
        )
    }

    return (
        <div className={"page"}>
            <div className={"languages"}>
                {languages}
            </div>
        </div>
    )
}
