import {Question} from "../../../models/Question";
import "./QuestionTimeStyles.scss";
import { Constants } from "../../../utils/Constants";

interface IProps {
  question: Question;
  onChangeDate: any;
  onChangeTime: any;
  showDate: boolean | undefined;
  showTime: boolean | undefined;
}

export const QuestionTimeView = (props: IProps) => {
  const isBlindModeOn: string = localStorage.getItem(Constants.BLIND_MODE)!;
  let picture;
  let date;
  let time;

  if (props.question.picture !== undefined && isBlindModeOn === 'false') {
    picture = (
      <div className={"question-picture"}>
        <img src={props.question.picture} alt={props.question.title}/>
      </div>
    )
  }

  if (props.showDate) {
    date = (
      <div className={"date"}>
        <input type={"date"} onChange={props.onChangeDate}/>
        <img src={process.env.PUBLIC_URL + "/pictos/calendar.svg"} alt="" />
      </div>
    )
  }

  if (props.showTime) {
    time = (
      <div className={"time"}>
        <input type={"time"} onChange={props.onChangeTime}/>
        <img src={process.env.PUBLIC_URL + "/pictos/time.svg"} alt="" />
      </div>
    )
  }

  return (
    <div className={"question-content question-time"}>
      {picture}
      <div className={"date-time"}>
        {date}
        {time}
      </div>
    </div>
  )
}
