import {Question} from "../../../models/Question";
import {Component} from "react";
import {QuestionFreeTextView} from "./QuestionFreeTextView";

interface IProps {
  question: Question;
  setQuestion: any;
  placeholder?: string;
}

interface IState {

}

export class QuestionFreeTextPage extends Component<IProps, IState> {
  state = {
    text: String
  }

  render() {
    return <QuestionFreeTextView
      question={this.props.question}
      onChange={this.onChange.bind(this)}
      placeholder={this.props.placeholder}/>
  }

  private onChange(event: any) {
    this.setState({text: event.target.value});
    let answer = this.props.question.answer;
    if (answer) {
      answer.value = event.target.value;
      this.props.setQuestion(this.props.question);
    }
  }
}
