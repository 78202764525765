import React, { Component } from 'react';
import ImageMapper from "react-img-mapper";

import { ImageMapper as ImageMapperType } from './QuestionClickableImagePage';

interface ViewProps {
  listImagesMapper: ImageMapperType[];
  onAreaClick: (areaId: string, clickedAreas: string[]) => void;
  clickedAreas: string[]
}

interface ViewState {
  viewClickedAreas: string[];
}

class View extends Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);
    this.state = {
      viewClickedAreas: props.clickedAreas
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.clickedAreas !== this.props.clickedAreas) {
      this.setState({
        viewClickedAreas: this.props.clickedAreas
      });
    }
  }

  handleAreaClick = (area: any) => {
    const { onAreaClick } = this.props;
    const { viewClickedAreas } = this.state;
    onAreaClick(area.id, viewClickedAreas);
  }

  render() {
    const { listImagesMapper } = this.props;
    const { viewClickedAreas } = this.state;
    return (
      <>
        {listImagesMapper?.map((image: any) => <ImageMapper
          src={image.imageSrc}
          map={image.map}
          stayMultiHighlighted
          toggleHighlighted
          onClick={this.handleAreaClick} />)}
      </>
    );
  }
}

export default View;
