import {Component} from "react";
import {MenuView} from "./MenuView";
import {cleanCookies} from "universal-cookie/lib/utils";
import {NewsController} from "../../controllers/NewsController";
import {News} from "../../models/News";
import {LoadingView} from "../loading/LoadingView";

interface IProps {
    onMenuClick: any;
}

interface IState {
    news: News[];
}

export class MenuPage extends Component<IProps, IState> {
    newsController = new NewsController();

    componentDidMount() {
        this.newsController.getList().then(news => this.setState({news}));
    }

    render() {
        if (!this.state) {
            return <LoadingView/>
        } else {
            return <MenuView onMenuClick={this.props.onMenuClick}
                             onIdClick={this.onIdClick.bind(this)}
                             onHomeClick={this.onHomeClick.bind(this)}
                             content={this.state.news.map(n => n.content).join()}/>
        }
    }

    onIdClick() {
        cleanCookies();
        window.location.reload();
    }

    onHomeClick() {
        window.location.href = "/";
    }
}