import {Component} from "react";
import {Question} from "../../models/Question";
import {QuestionController} from "../../controllers/QuestionController";
import "./QuestionStyles.scss"
import {QuestionView} from "./QuestionView";
import {IProps as IFooterProps} from "../../components/question-footer/QuestionFooter";
import {Constants} from "../../utils/Constants";
import {QuestionNumberPage} from "./number/QuestionNumberPage";
import {QuestionThermometerPage} from "./thermometer/QuestionThermometerPage";
import {QuestionYesNoPage} from "./yes-no/QuestionYesNoPage";
import {QuestionCheckboxesPage} from "./checkboxes/QuestionCheckboxesPage";
import {Interval} from "../../models/Interval";
import {QuestionOtherView} from "./other/QuestionOtherView";
import {MenuPage} from "../menu/MenuPage";
import {LoadingView} from "../loading/LoadingView";
import {StoredAnswer} from "../../models/StoredAnswer";
import {AnswerController} from "../../controllers/AnswerController";
import Cookies from "universal-cookie";
import FileSaver from 'file-saver';
import {QuestionTimePage} from "./time/QuestionTimePage";
import {ApiLanguage} from "../../models/api/ApiLanguage";
import {QuestionNoResponsePage} from "./no-response/QuestionNoResponsePage";
import {QuestionFreeTextPage} from "./free-text/QuestionFreeTextPage";
import QuestionClickableImagePage from "./clickable-image/QuestionClickableImagePage";


interface IProps {
  match?: {
    params: {
      id?: string;
    }
  }
}

interface IState {
  // questionMark: boolean;
  // handleQuestionMark: any;
  // handleQuestionIdk: any;
  question: Question;
  footer: IFooterProps;
  other: boolean;
  menu: boolean;
  submitting: boolean;
  lastQuestion: boolean;
}

export class QuestionPage extends Component<IProps, IState> {
  questionController = new QuestionController();
  answerController = new AnswerController();

  componentDidMount() {
    if (this.props.match?.params.id) {
      this.questionController.getById(this.props.match.params.id)
        .then(question => {
          this.onQuestionLoaded(question);
        });
    } else {
      window.location.href = "/";
    }
  }

  render() {
    if (this.state === null || this.state.question === null || this.state.submitting) {
      return <LoadingView/>
    } else if (this.state.menu) {
      return <MenuPage onMenuClick={this.onMenuClick.bind(this)}/>
    } else {
      let questionSubPage: JSX.Element = <div className={"question-content"}/>;

      if (this.state.other) {
        let other = this.state.question.answer?.options?.find(o => o.id === "other");
        questionSubPage = <QuestionOtherView onChange={this.onOtherChange.bind(this)}
                                             close={this.closeOther.bind(this)}
                                             value={other?.title}
                                             placeholder={other?.placeholder}/>
      } else if (this.state.question.answer?.type === "paragraph--answer_check_box") {
        questionSubPage = <QuestionCheckboxesPage question={this.state.question}
                                                  setQuestion={this.setQuestion.bind(this)}
                                                  openOther={this.openOther.bind(this)}/>
      } else if (this.state.question.answer?.type === "paragraph--yes_no_question") {
        questionSubPage = <QuestionYesNoPage question={this.state.question}
                                             setQuestion={this.setQuestionAndNext.bind(this)}/>
      } else if (this.state.question.answer?.type === "paragraph--answer_number") {
        if (this.state.question.answer.gauge) {
          questionSubPage = <QuestionThermometerPage question={this.state.question}
                                                     setQuestion={this.setQuestion.bind(this)}/>
        } else {
          questionSubPage = <QuestionNumberPage question={this.state.question}
                                                setQuestion={this.setQuestion.bind(this)}/>
        }
      } else if (this.state.question.answer?.type === "paragraph--clickable_image") {
        questionSubPage = <QuestionClickableImagePage question={this.state.question}
                                                      setQuestion={this.setQuestion.bind(this)}/>;
      } else if (this.state.question.answer?.type === "paragraph--answer_time") {
        questionSubPage = <QuestionTimePage question={this.state.question}
                                            setQuestion={this.setQuestion.bind(this)}/>
      } else if (this.state.question.answer?.type === "paragraph--question_no_reponse") {
        questionSubPage = <QuestionNoResponsePage question={this.state.question}/>;
      } else if (this.state.question.answer?.type === "paragraph--free_text") {
        let other = this.state.question.answer?.options?.find(o => o.id === "other");
        questionSubPage = <QuestionFreeTextPage question={this.state.question}
                                                setQuestion={this.setQuestion.bind(this)}
                                                placeholder={other?.placeholder}/>;
      }

      return <QuestionView title={this.state.question.title}
                          //  questionMark={this.state.questionMark}
                          //  handleQuestionMark={this.state.handleQuestionMark}
                          //  handleQuestionIdk={this.state.handleQuestionIdk}
                           questionSubPage={questionSubPage}
                           footer={this.state.footer}
                           onMenuClick={this.onMenuClick.bind(this)}/>
    }
  }

  private setQuestion(question: Question) {
    this.setState({question, footer: {...this.state.footer, next: question.answer?.value !== undefined}});
  }

  private setQuestionAndNext(question: Question) {
    this.setQuestion(question);
    this.onNextClick();
  }

  private onQuestionLoaded(question: Question) {
    let answer = question.answer;
    if (answer) {
      let storedAnswers: any = localStorage.getItem(Constants.KEY_ANSWERS);
      if (storedAnswers) {
        storedAnswers = JSON.parse(storedAnswers);

        let storedAnswer = (storedAnswers as StoredAnswer[]).find(a => a.id === question.id);
        if (storedAnswer) {
          answer.value = storedAnswer.value;

          if (answer.type === "paragraph--answer_check_box" && answer.options) {
            let options = answer.options;

            if (answer.multiValued) {
              if(Array.isArray(answer.value)) {
                if((answer.value as string[]).some(v => !options.map(o => o.id).includes(v))) {
                  let other = options.find(o => o.id === "other");
                  if (other) {
                    let otherTitle = (storedAnswer.value as string[]).find(v => !options.map(o => o.id).includes(v));
                    if (otherTitle !== undefined) {
                      other.title = otherTitle;
                      answer.value = [...(answer.value as string[]).filter(v => options.map(o => o.id).includes(v)), "other"];
                    }
                  }
                }
              } else if (!options.map(o => o.id).includes(answer.value as string)) {
                let other = options.find(o => o.id === "other");
                if (other) {
                  other.title = storedAnswer.value as string;
                  answer.value = storedAnswer.value;
                }
              }

            } else if (!options.map(o => o.id).includes(answer.value as string)) {
              let other = options.find(o => o.id === "other");
              if (other) {
                other.title = storedAnswer.value as string;
                answer.value = storedAnswer.value;
              }
            }
          }
        }
      }
    }

    this.setState({
      // questionMark: answer?.type !== "paragraph--question_no_reponse",
      // handleQuestionMark: this.onQuestionMarkClick.bind(this),
      // handleQuestionIdk: this.onIdkQuestion.bind(this),
      question,
      footer: {
        visible: true,
        previous: true,
        handlePrevious: this.onPreviousClick.bind(this),
        next: (answer && answer.value !== undefined) || answer?.type === "paragraph--question_no_reponse",
        handleNext: this.onNextClick.bind(this),
        handleQuestionMark: this.onQuestionMarkClick.bind(this),
        handleQuestionIdk: this.onIdkQuestion.bind(this),
        questionMark: answer?.type !== "paragraph--question_no_reponse",
      }
    });
  }

  private onPreviousClick() {
    // On retire la question de la liste des réponses enregistrées, si elle s'y trouve
    let answers: StoredAnswer[];
    let storedAnswers = localStorage.getItem(Constants.KEY_ANSWERS);
    if (storedAnswers) {
      answers = JSON.parse(storedAnswers);
    } else {
      answers = [];
    }
    let filteredAnswers = answers.filter(a => a.id !== this.state.question.id)
    localStorage.setItem(Constants.KEY_ANSWERS, JSON.stringify(filteredAnswers));

    window.history.back();
  }

  private onIdkQuestion() {
    const questionToEdit = this.state.question;
    if (questionToEdit.answer !== undefined) {
      questionToEdit.answer.value = "L'utilisateur n'a pas su répondre";
      this.setState({question: questionToEdit})
    }
    this.onQuestionMarkClick();
  }

  private onQuestionMarkClick() {
    this.saveAnswerToSession(this.state.question);

	  if (this.state.question.next) {
		  // next  question is merci we submit
		  if (this.state.question.next === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb' ) {
			  this.submitCr(this.state.question.next); // this willredirect
		  }
		  else {
			  window.location.href = "/question/" + this.state.question.next;
		  }
	  }
	  else {
		  window.location.href = "/";

	  }
  }

  private onNextClick() {
    let answer = this.state.question.answer;
    if (answer) {
      let next = this.saveAnswerToSessionAndGetNext(this.state.question);
		if (next) {
			if(next === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb'){
				this.submitCr(next); // this willredirect
        return;
			}
      window.location.href = "/question/" + next;
		}
		else {
			window.location.href = "/";
		}
    }
  }

  private onMenuClick() {
    this.setState({menu: !this.state.menu});
  }

  private openOther() {
    this.setState({other: true, footer: {...this.state.footer, visible: false}});
  }

  private closeOther() {
    this.setState({other: false, footer: {...this.state.footer, visible: true}});
  }

  private onOtherChange(event: any) {
    let question = this.state.question;
    let options = question.answer?.options;
    if (options) {
      let option = options.find(o => o.id === "other");
      if (option) {
        option.title = event.target.value;
        this.setState({question});
      }
    }
  }

  private saveAnswerToSession(question: Question) {
    this.saveAnswerToSessionAndGetNext(question);
  }

  private saveAnswerToSessionAndGetNext(question: Question): string | undefined {
    let next;
    let answer = question.answer;


    if ((answer && answer.value) || (answer && answer.value === 0)) {
      let value = answer.value;

      let options = answer.options;

      if (options) {
        if (answer.type === "paragraph--answer_check_box") {
          if (answer.multiValued) {
            options = options.filter(o => (value as string[]).includes(o.id));
            if (options.length > 0) {
              value = value as string[];
              if (value.includes("other")) {
                value = [...value.filter(v => options?.map(o => o.id).includes(v) && v !== "other"),
                  options.find(o => o.id === "other")?.title as string];
              }
              next = question.next;
            }
          } else if (value === "other") {
            value = options.find(o => o.id === "other")?.title as string;
            next = question.next;
          } else {
            let option = options.find(o => o.id === value);
            if (option) {
              next = option.next;
            }
          }
        } else if (answer.type === "paragraph--answer_number") {
          let option = (options as Interval[]).find(o => o.min <= value && o.max >= value);
          if (option) {
            next = option.next;
          }
        } else if (answer.type === "paragraph--free_text") {
          next = question.next;
        } else if(answer?.type === "paragraph--clickable_image") {
          if (Array.isArray(answer.value)) {
            next = answer.value![0];;
          }
        }
        else {
          let option = options.find(o => o.id === value);
          if (option) {
            next = option.next;
          }
        }
      } else {
        if(answer?.type === "paragraph--clickable_image") {
          if (Array.isArray(answer.value)) {
            next = answer.value![0];;
          } else next = question.next;
        } else {
          next = question.next;
        }
      } 

      let answers: StoredAnswer[];

      let storedAnswers = localStorage.getItem(Constants.KEY_ANSWERS);
      if (storedAnswers) {
        answers = JSON.parse(storedAnswers);
      } else {
        answers = [];
      }

      let storedAnswer = answers.find(a => a.id === this.state.question.id);
      if (storedAnswer) {
        storedAnswer.value = value;
      } else {
        storedAnswer = new StoredAnswer(this.state.question.id, value);
        answers.push(storedAnswer);
      }

      localStorage.setItem(Constants.KEY_ANSWERS, JSON.stringify(answers));
    }

    if (answer?.type === "paragraph--question_no_reponse") {
      next = question.next;
    }

    return next;
  }

  private submitCr(lastQuestionId: string) {
    let id = new Cookies().get(Constants.KEY_COOKIE);
    let data = JSON.parse(localStorage.getItem(Constants.KEY_ANSWERS) as string);
    let start = localStorage.getItem(Constants.KEY_START) as string;
    let langue: ApiLanguage = {
      name: localStorage.getItem(Constants.NAME_LANGUAGE) as string,
      code: localStorage.getItem(Constants.KEY_LANGUAGE) as string,
    }
    let category = localStorage.getItem(Constants.KEY_CATEGORY) as string;
    this.setState({submitting: true});
    this.answerController.postAnswers(id, data, start, langue, category).then(res => {
      // if (res.sent) {
      //   const blob = new Blob([res], {type: 'application/pdf'});
      //   //FileSaver.saveAs(blob, "Compte-rendu - " + start + " - " + id);
      //

      // if(lastQuestionId){
      //   window.location.href = "/question/" + lastQuestionId;
      // } else {
      //   window.location.href = "/";
      // }
    }).catch((error) => {
        console.error(error);
    }).finally(() => {
      window.location.href = "/question/" + lastQuestionId;
      // always set the submitting state to false, regardless of success or failure
      this.setState({submitting: false});
    });
  }
}
