import {BaseController} from "./BaseController";
import {Option} from "../models/Option";
import {ApiOptions} from "../models/api/ApiOptions";
import {Interval} from "../models/Interval";
import {MediaController} from "./MediaController";

export class OptionController extends BaseController {
    mediaController = new MediaController();

    getList(url: string): Promise<Option[]> {
        return super.get(url).then(async res => {
            let options: Option[] = [];

            for (let data of (res as ApiOptions).data) {
                let option: Option = new Option(data.id, data.attributes.field_answer_box_wording);

                if (data.type === "paragraph--answer_box") {
                    if (data.relationships.field_answer_box_next.data) {
                        option.next = data.relationships.field_answer_box_next.data.id;
                    }

                    if (data.relationships.field_image) {
                        let pictureUrl = data.relationships.field_image?.links.related.href;
                        if (pictureUrl && data.relationships.field_image.data) {
                            await this.mediaController.get(pictureUrl).then(media => {
                                option.picture = media.url;
                                option.pictureAlt = media.alt;
                            });
                        }
                    }
                }

                options.push(option);
            }

            return options;
        });
    }

    getIntervalList(url: string): Promise<Interval[]> {
        return super.get(url).then(async res => {
            let intervals: Interval[] = [];

            for (let data of (res as ApiOptions).data) {
                let interval: Interval = new Interval(data.id, data.attributes.field_answer_box_wording,
                    data.attributes.field_answer_interval_minimum, data.attributes.field_maximum);

                if (data.relationships.field_answer_interval_next.data) {
                    interval.next = data.relationships.field_answer_interval_next.data.id;
                }

                intervals.push(interval);
            }

            return intervals;
        });
    }
}