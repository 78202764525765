import {Option} from "./Option";

export class Interval extends Option {
    min: number;
    max: number;

    constructor(id: string, title: string, min: number, max: number) {
        super(id, title);
        this.min = min;
        this.max = max;
    }
}