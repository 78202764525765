import { QuestionFooter, IProps as IFooterProps } from "../../components/question-footer/QuestionFooter";
import { QuestionHeader } from "../../components/question-header/QuestionHeader";
import { ProgressBar } from "../../components/progress-bar/ProgressBar";
import { Constants } from "../../utils/Constants";
import React, { useState, useEffect } from 'react';
import useCustomStyle from "../../utils/hooks/useCustomeStyles";

interface IProps {
    title: string;
    questionSubPage: JSX.Element;
    footer?: IFooterProps
    onMenuClick: any;
    handleStyleClick?: any;
    // questionMark?: boolean;
    // handleQuestionMark?: any;
    // handleQuestionIdk?: any;
}

interface Style {
    color: string;
    backgroundColor: string;
    fontSize: string,
}

export const QuestionView = (props: IProps) => {

    const ref = React.useRef<HTMLDivElement>(null);
    const [isOverflow, setIsOverflow] = useState(false);
    const [isDownArrow, setIsDownArrow] = useState(true);
    
    const [answersCount, setAnswersCount] = useState<number>(0);

    const [fontSizeHeader, setFontSizeHeader] = useState<string>('50px');
    const [fontSizeBody, setFontSizeBody] = useState<string>('40px');
    const [smallDevice, setSmallDevice] = useState<boolean>(window.matchMedia("(max-width: 600px)").matches);
    
    
    useEffect(() => {
        if(smallDevice) {
                setFontSizeHeader('35px');
                setFontSizeBody('25px');
            }
    }, [])

    const styleHeader = useCustomStyle(
        'styleHeader',
        {
            color: 'white',
            backgroundColor: 'black',
            fontSize: fontSizeHeader
        });

    const styleBody = useCustomStyle(
        'styleBody',
        {
            color: 'white',
            backgroundColor: 'black',
            fontSize: fontSizeBody
        });

    const handleStyleClick = () => {
        styleHeader.handleClick();
        styleBody.handleClick();
    }

    // Detect overflow of div
    React.useLayoutEffect(() => {
        if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
            setIsOverflow(true)
        };
    })

    // Detect scroll to bottom of div
    const onScroll = () => {
        if (ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = ref.current;
            if (scrollTop + clientHeight < scrollHeight) {
                setIsDownArrow(false);
            } else {
                setIsDownArrow(true);
            }
        }
    };

    let className;

    if (isOverflow) {
        className = "arrow-overflow";
        // Not display arrow when checkbox "Saisie text" is checked
        if (props.questionSubPage.props.question?.answer.other === undefined) {
            className = "arrow-overflow--none";
        }
    } else {
        className = "arrow-overflow--none";
    }

    let storedAnswers = localStorage.getItem(Constants.KEY_ANSWERS);
    let currentQuestionId: string;

    if (props.questionSubPage.props.question?.answer.other !== undefined) { // If not "Saisie text"
        currentQuestionId = props.questionSubPage.props.question.id;
    }

    useEffect(() => {
        if (storedAnswers) {
            setAnswersCount((JSON.parse(storedAnswers).length));

            if (currentQuestionId === '5b7145a4-5fec-49b2-b8a1-a08f708cf6bb') {
                setAnswersCount(40);
            }
        }
    }, []);

    return (
        <div className={"page"}>
            <div className={"question"}>
                <QuestionHeader
                    title={props.title}
                    onMenuClick={props.onMenuClick}
                    handleStyleClick={() => handleStyleClick()}
                    newStyle={styleHeader.style} />
                <ProgressBar questionsCompletedCount={answersCount} show={true}></ProgressBar>
                <div
                    className={'question-container'} ref={ref} onScroll={() => onScroll()}
                    style={styleBody.style}
                >
                    <div className={className}>
                        <img src={process.env.PUBLIC_URL + "/pictos/fleche.svg"}
                            style={isDownArrow ? { transform: 'rotate(90deg)' } : { transform: 'rotate(270deg)' }} />
                    </div>
                    {props.questionSubPage}
                </div>
                <QuestionFooter {...props.footer} />
            </div>
        </div>
    )
}
