import "./GeneralConditions.scss";

const GeneralConditions = () => {

  return (
    <div className="cgu">
      <div className="cgu-box">
        <div className="cgu-box__title">Conditions Générales d’Utilisation - Utilisateurs Patients</div>
        {/* --- Condition 1 --- */}
        <div className="cgu-box__condition">
          <div className="condition-title">1. Objet – Acceptation – Création de compte</div>
          <div className="condition-content">
            <div className="text">
              <p>Vous intervenez comme utilisateur de la Solution éditée par la société MARTI (ci-après la
                « Société », l’« Utilisateur » et la « Solution ») qui permet de faciliter votre prise en charge aux
                urgences. </p>
              <p>Cette Solution est accessible depuis votre smartphone s’il bénéficie d’une version compatible
                à la Solution. Si tel n’est pas le cas, le service hospitalier dans lequel vous êtes pris en charge
                vous met à disposition un support aux fins d’accéder à la Solution.</p>
              <p>Depuis la Solution, vous allez pouvoir répondre à un questionnaire, dans votre langue et à
                l’aide de pictogrammes, vous permettant d’expliquer vos symptômes et vos antécédents (ou
                ceux de votre enfant si celui-ci est mineur).</p>
              <p>L’autorisation parentale est obligatoire pour les mineurs de moins de 15 ans, le consentement
                d’un seul des titulaires de l’autorité parentale étant suffisant. Au-delà de 15 ans, le
                consentement peut être donné par le mineur seul.</p>
              <p>Avant la première utilisation de la Solution, vous devez prendre connaissance des présentes
                Conditions d’Utilisation et les accepter d’un clic, ce qui vous permet désormais d’accéder aux
                fonctionnalités de la Solution. </p>
              <p>Les présentes Conditions d’Utilisation peuvent être modifiées à tout moment. Dans un tel cas,
                pour que les nouvelles Conditions d’Utilisation vous soient applicables, vous devrez réitérer
                votre acceptation lors d’une prochaine utilisation. A défaut, tout accès à la Solution vous sera
                refusé. </p>
              <p>L’usage de la Solution se fait sous votre seule responsabilité.</p>
            </div>
          </div>
        </div>

        {/* --- Condition 2 --- */}
        <div className="cgu-box__condition">
          <div className="condition-title">2. Description de la Solution</div>
          <div className="condition-content">
            <div className="text">
              <p>La Solution permet d’accéder au questionnaire à remplir par vous-même, pour vous-même ou
                votre enfant si celui-ci est mineur.</p>
              <p>Une fois le questionnaire terminé, un compte-rendu médical est envoyé au format PDF au
                soignant qui vous prendra en charge, par le biais d’une plateforme sécurisée.</p>
              <p>Pour le cas où vous rédigeriez des réponses à des questions ouvertes dans une autre langue
                que le français, celles-ci seront traduites en français par l’API Google Translate.</p>
              <p>L’ensemble des fonctionnalités pouvant évoluer à tout instant, la Société pourra modifier à tout
                moment le contenu de la Solution, sa présentation et son fonctionnement sans que sa
                responsabilité ne puisse être engagée.</p>
            </div>
          </div>
        </div>

        {/* --- Condition 3 --- */}
        <div className="cgu-box__condition">
          <div className="condition-title">3. Obligations de l’Utilisateur</div>
          <div className="condition-content">
            <div className="text">
              <p>En tant qu’Utilisateur, vous déclarez connaître parfaitement les caractéristiques d’Internet et
                les contraintes liées à son utilisation, en particulier l’impossibilité de garantir l’intégrité et la
                confidentialité de toutes données y transitant.</p>
              <p>Vous vous engagez, à travers les données renseignées, communiquées et diffusées sur la
                Solution (ci-après les « Contenus »), à modérer votre langage et à ne pas divulguer ou diffuser
                de propos :</p>
              <ul>
                <li>&nbsp; &nbsp;de nature publicitaire ou promotionnel pour des produits ou services réglementés ou illicites,</li>
                <li>&nbsp; &nbsp;contraire à l’ordre public, et/ou non conformes aux dispositions légales et règlementaires en vigueur,</li>
                <li>&nbsp; &nbsp;à caractère diffamatoire, injurieux, outrancier, mensonger, discriminatoire, ou calomnieux à l’égard
                  d’un utilisateur, de votre employeur, d’un tiers,</li>
                <li>&nbsp; &nbsp;à caractère raciste, xénophobe, négationniste, incitant à la discrimination, à la haine
                  ou à la violence à l'égard d'une personne ou d'un groupe de personnes à raison de leur
                  origine, de leur sexe, de leur situation de famille, de leur apparence physique, de leur
                  patronyme, de leur état de santé, de leur handicap, de leurs caractéristiques
                  génétiques, de leurs mœurs, de leur orientation sexuelle vraie ou supposée, de leur
                  âge, de leurs opinions politiques, de leurs activités syndicales, de leur appartenance
                  vraie ou supposée à une ethnie, une nation, une race, ou une religion déterminée,</li>
                <li>&nbsp; &nbsp;contenant des virus, ou tout programme informatique de nature à perturber,
                  interrompre, détruire en tout ou en partie le service assuré par la Solution.</li>
              </ul>

              <p>De même, vous vous engagez à ne pas porter atteinte aux droits de propriété intellectuelle
                d’un tiers, à la vie privée ou à l’intimité de la vie privée, à l’image d’une personne ou à la
                notoriété ou réputation d’une entreprise, ni diffuser aucune information de nature confidentielle
                ou dont la diffusion constituerait la violation d’un engagement contractuel vis-à-vis d’un tiers.
                Vous déclarez en conséquence disposer des droits ou autorisations vous permettant l’usage
                des Contenus.</p>

              <p>Vous renoncez à exercer tout recours contre la Société dans le cadre de poursuites diligentées
                par un tiers du fait de la publication de tout propos ou avis, ou du fait de tout manquement aux
                obligations découlant des présentes.</p>
              <p>Vous reconnaissez qu’il est interdit de réaliser toute action de nature à interrompre la Solution,
                à restreindre sa disponibilité, à empêcher sa continuité. Toute intrusion, ou tentative
                d’intrusion, dans la Solution, détournement de données, atteinte aux mesures de sécurité et
                d’authentification de la Solution ouvrent droit à des poursuites judiciaires.</p>
              <p>Enfin, vous vous engagez, en réponse au questionnaire, à fournir les Contenus les plus
                complets, véridiques et exactes possibles.</p>

            </div>
          </div>
        </div>

        {/* --- Condition 4 --- */}
        <div className="cgu-box__condition">
          <div className="condition-title">4. Responsablité</div>
          <div className="condition-content">
            <div className="text">
              <p>La Société ne pourra être tenue pour responsable de tout dommage matériel, ou
                immatériel, direct ou indirect, quelles qu’en soient les causes (y compris les dommages
                pouvant être causés par l’éventuelle diffusion de virus, par la fraude informatique ou du fait
                des contraintes et limites du réseau Internet), ni des conséquences résultant de :</p>
              <ul>
                <li>&nbsp; &nbsp;De votre utilisation de la Solution ;</li>
                <li>&nbsp; &nbsp;Des Contenus inexactes, erronés ou incomplets que vous avez pu renseigner</li>
                <li>&nbsp; &nbsp;De l’impossibilité d’avoir accès à la Solution et ses Contenus.</li>
              </ul>
              <p>La Société ne pourra voir sa responsabilité engagée quelle que soit l’interprétation médicale
                qui découle du questionnaire que vous avez rempli.</p>
              <p>Nous vous rappelons que la Solution constitue une aide à la prise en charge des patients se
                présentant dans les Services Hospitaliers et qu’elle ne peut se substituer à un examen clinique
                réalisé par un soignant.</p>
              <p>Par ailleurs, la Société décline toute responsabilité et garantie relatives aux traductions
                proposées par Google Translate y compris toute garantie d’exactitude et de fiabilité.</p>

            </div>
          </div>
        </div>

        {/* --- Condition 5 --- */}
        <div className="cgu-box__condition">
          <div className="condition-title">5.Propriété intellectuelle</div>
          <div className="condition-content">
            <div className="text">
              <p>La Société est l’auteur de la Solution : elle est et restera titulaire exclusif de l’ensemble des droits
                d’exploitation de la Solution.</p>

              <p>La Société vous concède indirectement une licence limitée, personnelle, non transmissible et
                non exclusive, gratuite aux fins d’utiliser la Solution dans les conditions prévues aux présentes.
                Tout autre usage est expressément exclu.</p>

              <p>Les contenus mis à votre disposition dans le cadre de la Solution sont utilisables uniquement
                dans ce cadre, sans droit d’utilisation, reproduction et/ou diffusion à titre commercial, et sous
                votre seule responsabilité.</p>

              <p>Vous ne pouvez en aucun cas, que ce soit directement ou avec l’assistance d’un tiers, sans
                l’accord préalable et écrit de la Société, sauf dans les conditions strictement prévues par la
                loi :</p>

              <ul>
                <li>&nbsp; &nbsp;Modifier la Solution,</li>
                <li>&nbsp; &nbsp;Reproduire la Solution,</li>
                <li>&nbsp; &nbsp;Réaliser de l’ingéniérie inversée,</li>
                <li>&nbsp; &nbsp;Diffuser la Solution à titre commercial,</li>
                <li>&nbsp; &nbsp;Arranger, adapter, traduire la Solution,</li>
                <li>&nbsp; &nbsp;Commercialiser la Solution,</li>
                <li>&nbsp; &nbsp;Sous-licencier, distribuer, transférer les droits qui vous sont concédés sur la Solution ;</li>
                <li>&nbsp; &nbsp;Corriger la Solution,</li>
                <li>&nbsp; &nbsp;Décompiler la Solution.</li>
              </ul>

            </div>
          </div>
        </div>

        {/* --- Condition 6 --- */}
        <div className="cgu-box__condition">
          <div className="condition-title">6. Données Personnelles</div>
          <div className="condition-content">
            <div className="text">
              <p>Lors de l’utilisation de la Solution, vous allez être amenés à remplir un questionnaire avec
                vos Données Personnelles (« Données Personnelles » désignent toutes les données ou
                les informations qui permettent d’identifier directement ou indirectement une personne
                physique.) et notamment vos noms, prénoms, date de naissance, langue et données
                médicales et sociales.</p>

              <p>Il conviendra alors d’indiquer votre consentement exprès à la collecte de ces Données
                Personnelles dans les conditions prévues par la loi n°78-17 du 6 janvier 1978 modifiée, en
                cliquant sur la case appropriée.</p>

              <p>Pour plus d’informations concernant la collecte et le traitement de vos Données Personnelles,
                veuillez consulter notre politique de confidentialité disponible ici (lien vers &nbsp;
                <a href="/privacy-policy" target='_blank'>
                politique de confidentialité</a>
                )
              </p>

              <ul>Société MARTI, éditeur de la Solution :
                <li>&nbsp; &nbsp;Société par actions simplifiée au capital de 5 000€</li>
                <li>&nbsp; &nbsp;Siège social : 132 Cours Albert Thomas 69008 LYON</li>
                <li>&nbsp; &nbsp;RCS de LYON : 918 027 632</li>
                <li>&nbsp; &nbsp;Directeur de publication et Président : Monsieur Quentin PAULIK</li>
                <li>&nbsp; &nbsp;Contact MARTI : <a href="contact@martiapp.fr"> contact@martiapp.fr</a></li>
              </ul>

              <ul>Société AZNETWORK, Hébergeur de la Solution :
                <li>&nbsp; &nbsp;Société par actions simplifiée au capital de 1 130 000 €</li>
                <li>&nbsp; &nbsp;Siège social : 40 Rue Ampère - 61000 ALENCON</li>
                <li>&nbsp; &nbsp;RCS d’Alençon : 423 880 061</li>
                <li>&nbsp; &nbsp;Président : Monsieur Frédéric MOREL</li>
                <li>&nbsp; &nbsp;Contact : 02 33 32 12 47 / <a href="contact@aznetwork.eu"> contact@aznetwork.eu</a></li>

              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralConditions;