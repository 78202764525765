import {ClipLoader} from "react-spinners";


interface IProps {
    size: number;
}

export const LoadingComponent = (props: IProps) => {
    return (
        <ClipLoader size={props.size}
                    color={"#CCCCFF"}/>
    )
}