import "./QuestionFooter.scss";
import {Constants} from "../../utils/Constants";

export interface IProps {
  visible?: boolean;
  previous?: boolean;
  handlePrevious?: any;
  next?: boolean;
  handleNext?: any;
  questionMark?: boolean;
  handleQuestionMark?: any;
  handleQuestionIdk?: any;
}

export const QuestionFooter = (props: IProps) => {
  if (props.visible) {
    return (
      <div className={"question-footer"}>
        <img src={process.env.PUBLIC_URL + "/pictos/fleche.svg"}
             className={'previous'}
             onClick={() => props.handlePrevious()}
             style={{visibility: props.previous ? "visible" : "hidden"}}
             alt={"Précédent"}/>
        <div className={'btn-idk'}
            style={{visibility: props.questionMark ? "visible" : "hidden"}}
            onClick={() => props.handleQuestionIdk()}>
          <img src={process.env.PUBLIC_URL + "/pictos/shrug.png"}
                alt={"Je ne sait pas"}/>
          <div>{localStorage.getItem(Constants.KEY_TRANSLATE_IDK)}</div>
        </div>
        <img src={process.env.PUBLIC_URL + "/pictos/fleche.svg"}
             className={'next'}
             onClick={() => props.handleNext()}
             style={{visibility: props.next ? "visible" : "hidden"}}
             alt={"Suivant"}/>
      </div>
    )
  } else {
    return null;
  }
}
