import {Component} from "react";
import {Question} from "../../../models/Question";
import {QuestionThermometerView} from "./QuestionThermometerView";

interface IProps {
    question: Question;
    setQuestion: any;
}

export class QuestionThermometerPage extends Component<IProps> {
    constructor(props: IProps) {
        super(props);
        this.onThermometerChange(props.question.answer?.value as number);
    }

    render() {
        return <QuestionThermometerView question={this.props.question} onChange={this.onThermometerChange.bind(this)}/>
    }

    private onThermometerChange(value: number) {
        let question = this.props.question;
        let answer = question.answer;
        if (answer) {
            answer.value = value;
            this.props.setQuestion(question);
        }
    }
}