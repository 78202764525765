import {Component} from "react";
import "./ServiceStyles.scss"
import {IProps as IFooterProps} from "../../components/question-footer/QuestionFooter";
import {Constants} from "../../utils/Constants";
import {LoadingView} from "../loading/LoadingView";
import {ServiceController} from "../../controllers/ServiceController";
import {Service} from "../../models/Service";
import {ServiceView} from "./ServiceView";

interface IState {
    services: Service[];
    selected?: Service;
    footer: IFooterProps;
}

export class ServicePage extends Component<{}, IState> {
    serviceController = new ServiceController();

    componentDidMount() {
        localStorage.removeItem(Constants.KEY_ANSWERS);
        localStorage.removeItem(Constants.KEY_START);
        localStorage.removeItem(Constants.KEY_FIRST_QUESTION);
        localStorage.removeItem(Constants.KEY_CATEGORY);
        localStorage.removeItem(Constants.BLIND_MODE);

        this.serviceController.getServices().then(res => {
            this.setState({
                services: res,
                footer: {
                    visible: true,
                    previous: false,
                    handlePrevious: null,
                    next: false,
                    handleNext: this.onNextClick.bind(this)
                }
            });
        })
    }

    render() {
        if (this.state === null || this.state.services === null) {
            return <LoadingView/>
        } else {
            return <ServiceView
              footer={this.state.footer}
              services={this.state.services}
              selected={this.state.selected}
              onOptionClick={this.onOptionCLick.bind(this)}
            />
        }
    }

    private onNextClick() {
        if (this.state.selected?.firstQuestionId) {
            localStorage.setItem(Constants.KEY_CATEGORY, this.state.selected.id);
            localStorage.setItem(Constants.KEY_FIRST_QUESTION, this.state.selected?.firstQuestionId);
            localStorage.setItem('SERVICE_SELECTED', JSON.stringify(this.state.selected));
            //window.location.href = "scan";

            window.location.href = this.state.selected.name;
        }
    }

    private onOptionCLick(serviceId: string) {
        let service = this.state.services.find(s => s.id === serviceId);
        if (service !== null) {
            this.setState({
                selected: service,
                footer: {
                  ...this.state.footer,
                    next: true,
                }
            });
        }
    }
}
