import React, { Component } from 'react';
import View from './QuestionClickableImageView';


export type Area = {
    id: string;
    shape: 'rect' | 'circle' | 'poly';
    coords: number[];
    preFillColor: string;
};

export type ImageAreas = {
    name: string;
    areas: Area[];
};

export type ImageMapper = {
    imageSrc: string;
    map: ImageAreas;
}

interface PageProps { }

interface PageState {
    clickedAreas: string[];
    listImagesMapper: ImageMapper[];
}


const imageBodySrc = `${process.env.PUBLIC_URL}/pictos/body.png`;
const imageBodyBackSrc = `${process.env.PUBLIC_URL}/pictos/body_a.png`;
const imageFaceSrc = `${process.env.PUBLIC_URL}/pictos/face.png`;


class Page extends Component<PageProps, PageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            clickedAreas: [],
            listImagesMapper: [],
        };
    }


    componentDidMount() {
        const htmlCode = `
    <img src=${imageFaceSrc} usemap="#image-face-map">
    <map name="image-map">
        <area target="" alt="Oeil gauche" title="Oeil gauche" href="javascript:" coords="106,228,307,231,307,376,106,374" shape="poly">
        <area target="" alt="Oeil droite" title="Oeil droite" href="javascript:" coords="337,230,531,230,533,376,337,378" shape="poly">
        <area target="" alt="Oreille gauche" title="Oreille gauche" href="javascript:" coords="56,236,104,235,105,373,90,474,65,409,49,417,34,411,26,356,17,302,32,289,48,293,54,299" shape="poly">
        <area target="" alt="Oreille droite" title="Oreille droite" href="javascript:" coords="578,239,538,240,535,266,535,346,535,346,534,287,536,390,543,431,550,470,570,412,586,415,596,410,602,387,608,347,612,300,602,289,583,289,580,263" shape="poly">
        <area target="" alt="Joue gauche" title="Joue gauche" href="javascript:" coords="108,373,223,373,221,563,189,565,166,553,137,531,122,517,108,498,90,473" shape="poly">
        <area target="" alt="Joue droite" title="Joue droite" href="javascript:" coords="420,376,535,375,549,464,537,491,523,507,508,521,495,533,482,546,465,555,451,563,419,565" shape="poly">
        <area target="" alt="Nez" title="Nez" href="javascript:" coords="223,373,420,378,421,466,226,465" shape="poly">
        <area target="" alt="Bouche" title="Bouche" href="javascript:" coords="223,465,418,467,418,566,220,565" shape="poly">
        <area target="" alt="Front" title="Front" href="javascript:" coords="61,228,73,169,96,121,128,79,168,44,209,20,252,8,301,0,352,2,377,6,414,17,448,31,481,55,505,77,529,106,543,129,564,171,571,193,576,220,577,231" shape="poly">
    </map>
    <img src=${imageBodySrc} usemap="#image-map">
    <map name="image-body-map">
        <area target="" alt="Bras gauche" title="Bras gauche" href="javascript:" coords="141,866,130,886,123,911,113,931,103,965,97,991,95,1018,122,1027,154,1039,183,1051,212,1064,236,1073,248,1048,258,1024,271,997,277,970,281,951" shape="poly">
        <area target="" alt="Bras droit" title="Bras droit" href="javascript:" coords="820,950,963,871,989,933,1002,986,1008,1016,869,1075,844,1027,831,992" shape="poly">
        <area target="" alt="Coude gauche" title="Coude gauche" href="javascript:" coords="92,1015,88,1035,88,1055,83,1081,72,1100,67,1116,67,1142,208,1158,206,1122,235,1074" shape="poly">
        <area target="" alt="Coude droit" title="Coude droit" href="javascript:" coords="869,1074,896,1118,896,1158,1038,1140,1028,1100,1016,1061,1008,1014" shape="poly">
        <area target="" alt="Avant bras gauche" title="Avant bras gauche" href="javascript:" coords="63,1140,209,1159,211,1201,209,1243,207,1281,200,1324,67,1316,61,1251,61,1205" shape="poly">
        <area target="" alt="Avant bras droit" title="Avant bras droit" href="javascript:" coords="892,1158,1038,1140,1042,1188,1042,1226,1039,1255,1038,1285,1034,1315,903,1323,896,1285,894,1253,890,1223" shape="poly">
        <area target="" alt="Main gauche" title="Main gauche" href="javascript:" coords="174,1406,189,1427,208,1477,227,1522,247,1552,236,1557,220,1554,202,1533,187,1510,170,1517,154,1636,143,1642,128,1636,129,1604,138,1540,124,1539,98,1641,91,1650,79,1649,73,1635,94,1525,85,1533,50,1614,38,1614,29,1606,53,1512,42,1519,18,1559,2,1558,0,1548,34,1469,52,1428,77,1393" shape="poly">
        <area target="" alt="Main droite" title="Main droite" href="javascript:" coords="925,1407,909,1434,905,1450,898,1471,888,1492,878,1511,870,1528,857,1556,867,1562,882,1554,898,1542,912,1514,927,1511,936,1531,938,1557,942,1572,949,1633,958,1641,969,1640,978,1632,969,1538,979,1536,1001,1640,1009,1651,1021,1647,1028,1639,1011,1532,1018,1528,1051,1616,1066,1618,1075,1605,1046,1516,1053,1512,1088,1560,1102,1558,1102,1547,1077,1490,1055,1445,1044,1416,1035,1403,1024,1393" shape="poly">
        <area target="" alt="Poitrine" title="Poitrine" href="javascript:" coords="330,636,370,622,732,622,769,632,808,914,806,977,799,1030,304,1030,297,984,289,941,290,904" shape="poly">
        <area target="" alt="Ventre" title="Ventre" href="javascript:" coords="524,1482,567,1480,610,1495,633,1522,652,1553,658,1585,649,1624,636,1648,632,1658,690,1633,735,1597,760,1571,781,1538,798,1501,806,1467,807,1425,800,1375,807,1242,797,1029,302,1030,297,1191,296,1264,300,1342,302,1390,294,1423,294,1481,306,1523,325,1564,360,1604,407,1640,463,1656,452,1636,446,1616,444,1580,450,1541,469,1515,494,1495,507,1489" shape="poly">
        <area target="" alt="Organes génitaux" title="Organes génitaux" href="javascript:" coords="456,1634,443,1602,445,1572,455,1533,483,1502,517,1484,548,1478,584,1483,613,1494,630,1513,645,1536,656,1560,658,1590,657,1610,650,1628,642,1642,633,1651,467,1654" shape="poly">
        <area target="" alt="Cuisse gauche" title="Cuisse gauche" href="javascript:" coords="294,1419,201,1750,440,1810,474,1744,502,1653,465,1654,437,1645,406,1636,376,1614,355,1594,333,1573,319,1552,307,1528,299,1502,296,1482,294,1462,295,1443" shape="poly">
        <area target="" alt="Cuisse droite" title="Cuisse droite" href="javascript:" coords="603,1651,612,1694,626,1730,635,1756,652,1787,664,1812,903,1752,888,1707,880,1674,868,1632,859,1594,850,1566,840,1527,830,1494,823,1465,809,1421,806,1447,806,1474,795,1503,788,1524,779,1543,770,1558,755,1581,740,1596,725,1607,708,1621,694,1630,683,1636,666,1642,646,1650,632,1650,616,1651" shape="poly">
        <area target="" alt="Genou gauche" title="Genou gauche" href="javascript:" coords="202,1749,198,1767,195,1788,195,1813,195,1829,196,1845,200,1859,206,1879,213,1897,219,1916,231,1938,424,1918,404,1897,388,1876,401,1861,416,1847,425,1834,434,1818,439,1809" shape="poly">
        <area target="" alt="Genou droit" title="Genou droit" href="javascript:" coords="662,1809,670,1821,680,1837,692,1853,704,1867,716,1876,704,1887,695,1898,682,1915,874,1937,884,1911,891,1888,900,1864,905,1844,907,1815,906,1789,902,1760,901,1753" shape="poly">
        <area target="" alt="Jambe droite" title="Jambe droite" href="javascript:" coords="683,1915,874,1937,852,1997,826,2047,817,2061,808,2077,794,2094,782,2114,770,2130,615,2107,630,2042,644,1996,659,1956" shape="poly">
        <area target="" alt="Talon gauche" title="Talon gauche" href="javascript:" coords="334,2129,490,2104,501,2150,508,2193,521,2216,414,2233,396,2207,375,2181,354,2151" shape="poly">
        <area target="" alt="Talon droit" title="Talon droit" href="javascript:" coords="766,2130,698,2220,697,2234,585,2218,594,2195,601,2158,615,2105" shape="poly">
        <area target="" alt="Pied gauche" title="Pied gauche" href="javascript:" coords="519,2215,529,2230,532,2246,534,2268,526,2294,516,2309,504,2328,494,2356,484,2398,473,2417,450,2430,429,2427,414,2414,436,2373,421,2384,410,2414,393,2419,380,2412,373,2399,388,2372,372,2392,355,2403,344,2394,346,2377,357,2359,337,2383,322,2390,313,2383,315,2370,330,2350,314,2362,300,2350,302,2337,326,2319,341,2302,360,2281,387,2254,411,2232,466,2225" shape="poly">
        <area target="" alt="Pied droit" title="Pied droit" href="javascript:" coords="585,2218,572,2232,568,2246,570,2267,573,2282,584,2303,595,2324,605,2347,614,2369,617,2392,625,2410,644,2428,667,2430,685,2423,686,2409,669,2374,676,2381,685,2394,692,2414,709,2420,722,2410,729,2396,714,2370,726,2383,740,2401,757,2399,760,2381,741,2354,756,2364,772,2388,785,2387,790,2371,796,2360,803,2350,800,2334,776,2319,767,2309,746,2286,725,2265,709,2249,693,2234,660,2228" shape="poly">
        <area target="" alt="Poignet gauche" title="Poignet gauche" href="javascript:" coords="67,1312,200,1323,176,1408,81,1395,75,1360" shape="poly">
        <area target="" alt="Poignet droit" title="Poignet droit" href="javascript:" coords="903,1322,1033,1313,1025,1363,1025,1394,928,1407,916,1370" shape="poly">
        <area target="" alt="Epaule gauche" title="Epaule gauche" href="javascript:" coords="333,630,275,652,232,687,214,710,192,743,179,777,166,805,155,833,141,864,155,875,181,889,195,898,219,913,238,925,256,934,266,943,283,950,288,908,296,894,302,861,305,828,314,771,324,703" shape="poly">
        <area target="" alt="Epaule droite" title="Epaule droite" href="javascript:" coords="770,631,794,640,820,647,849,669,861,680,885,705,902,733,918,765,932,800,940,820,954,848,963,869,938,882,913,897,881,915,853,930,838,940,819,951,805,898,785,763" shape="poly">
        <area target="" alt="Jambe gauche" title="Jambe gauche" href="javascript:" coords="230,1938,241,1969,251,1994,268,2031,289,2068,334,2130,489,2107,477,2062,463,2017,452,1985,442,1954,424,1918" shape="poly">
        <area target="" alt="Cou" title="Cou" href="javascript:" coords="420,565,457,581,440,594,410,606,375,620,737,623,709,612,683,603,665,595,652,579,676,567" shape="poly">
    </map>
    <img src=${imageBodyBackSrc} usemap="#image-body-map">
    <map name="image-body-back-map">
        <area target="" alt="Bras gauche arrière" title="Bras gauche arrière" href="javascript:" coords="231,988,239,875,239,688,165,741,76,941,35,1095,150,1096,192,1094" shape="poly">
        <area target="" alt="Bras droit arrière" title="Bras droit arrière" href="javascript:" coords="763,680,844,753,890,858,938,973,960,1055,960,1090,812,1093,789,1055,764,971" shape="poly">
        <area target="" alt="Coude gauche arrière" title="Coude gauche arrière" href="javascript:" coords="188,1095,32,1094,13,1182,154,1181,159,1150" shape="poly">
        <area target="" alt="Coude droit arrière" title="Coude droit arrière" href="javascript:" coords="811,1092,964,1092,978,1142,984,1180,844,1182,839,1142" shape="poly">
        <area target="" alt="Avant bras gauche arrière" title="Avant bras gauche arrière" href="javascript:" coords="158,1246,156,1324,144,1375,21,1379,7,1288,6,1248,14,1180,157,1183" shape="poly">
        <area target="" alt="Avant bras droit arrière" title="Avant bras droit arrière" href="javascript:" coords="842,1178,984,1178,989,1247,985,1332,977,1377,857,1378,843,1294,840,1236" shape="poly">
        <area target="" alt="Dos haut" title="Dos haut" href="javascript:" coords="237,679,383,635,625,639,763,681,761,972,240,970" shape="poly">
        <area target="" alt="Dos bas" title="Dos bas" href="javascript:" coords="244,974,758,975,748,1131,752,1273,750,1420,252,1424,244,1267,254,1120" shape="poly">
        <area target="" alt="Fesses" title="Fesses" href="javascript:" coords="252,1423,753,1422,768,1462,762,1529,729,1602,657,1643,586,1657,508,1625,436,1655,342,1645,287,1609,249,1562,236,1494" shape="poly">
        <area target="" alt="Cuisse gauche arrière" title="Cuisse gauche arrière" href="javascript:" coords="229,1495,151,1786,389,1847,427,1765,460,1651,380,1660,276,1614,241,1560" shape="poly">
        <area target="" alt="Cuisse droite arrière" title="Cuisse droite arrière" href="javascript:" coords="568,1749,619,1849,850,1788,768,1494,739,1598,664,1652,582,1666,538,1648" shape="poly">
        <area target="" alt="Genou gauche arrière" title="Genou gauche arrière" href="javascript:" coords="151,1784,146,1880,178,1968,314,1955,370,1949,344,1906,385,1846" shape="poly">
        <area target="" alt="Genou droit arrière" title="Genou droit arrière" href="javascript:" coords="615,1853,663,1913,630,1949,819,1968,841,1924,856,1866,849,1790" shape="poly">
        <area target="" alt="Jambe gauche arrière" title="Jambe gauche arrière" href="javascript:" coords="367,1950,400,2004,420,2080,453,2211,324,2215,244,2113,206,2044,178,1965" shape="poly">
        <area target="" alt="Cheville gauche arrière" title="Cheville gauche arrière" href="javascript:" coords="320,2213,454,2211,483,2332,390,2331,360,2312,350,2260" shape="poly">
        <area target="" alt="Cheville droite arrière" title="Cheville droite arrière" href="javascript:" coords="551,2207,680,2206,647,2279,643,2309,601,2333,520,2330" shape="poly">
        <area target="" alt="Talon gauche arrière" title="Talon gauche arrière" href="javascript:" coords="482,2331,488,2418,460,2449,340,2432,259,2383,290,2330,330,2302,392,2340" shape="poly">
        <area target="" alt="Talon droit arrière" title="Talon droit arrière" href="javascript:" coords="518,2330,507,2387,515,2421,560,2449,660,2428,740,2387,727,2345,669,2307,604,2338" shape="poly">
        <area target="" alt="Poignet gauche arrière" title="Poignet gauche arrière" href="javascript:" coords="25,1378,144,1372,122,1444,140,1464,20,1465,33,1434" shape="poly">
        <area target="" alt="Poignet droit arrière" title="Poignet droit arrière" href="javascript:" coords="861,1377,978,1375,972,1430,979,1464,865,1463,880,1441" shape="poly">
        <area target="" alt="Jambe droite arrière" title="Jambe droite arrière" href="javascript:" coords="631,1947,821,1973,778,2076,735,2139,680,2210,549,2203,574,2097,599,2013" shape="poly">
        <area target="" alt="Cou arrière" title="Cou arrière" href="javascript:" coords="379,574,627,575,599,587,605,619,620,638,382,633,395,622,402,587" shape="poly">
        <area target="" alt="Main gauche" title="Main gauche" href="javascript:" coords="149,1461,21,1461,2,1511,9,1557,15,1593,58,1633,128,1635,145,1615,123,1588,136,1566,155,1564,166,1542,160,1487" shape="poly">
        <area target="" alt="Main droite" title="Main droite" href="javascript:" coords="857,1461,834,1495,834,1544,850,1569,866,1560,877,1591,853,1624,872,1640,902,1637,936,1634,973,1605,990,1580,998,1520,982,1462" shape="poly">
    </map>
    `;

        let el: HTMLElement = document.createElement("div");
        el.innerHTML = htmlCode;
        let tmp: ImageMapper[] = [];
        if (el.children.length) {
            for (let i = 0; i < el.children.length; i += 2) {
                let imageSrc: string = el.children[i].attributes.getNamedItem("src")?.value!;
                let imageName: string = el.children[i + 1].attributes.getNamedItem("name")?.value!;
                let areas: any[] = [];

                for (let htmlArea of Array.prototype.slice.call(el.children[i + 1].children) as HTMLAreaElement[]) {
                    let area = {} as any;
                    area.id = htmlArea.title;
                    area.next = htmlArea.dataset.next;
                    area.coords = htmlArea.coords.split(",");
                    area.shape = htmlArea.shape;
                    area.preFillColor = "";
                    area.fillColor = "#590DE1";
                    areas.push(area);
                }

                tmp.push({
                    imageSrc: imageSrc,
                    map: {
                        name: imageName,
                        areas: areas
                    }
                })
            };
            this.setState({ listImagesMapper: tmp });
        }
    }

    onAreaClick = (areaId: string, clickedAreasTest: string[]) => {
        console.log('Before action', clickedAreasTest);
        const isExist = clickedAreasTest.includes(areaId);

        if (isExist) {
            const updatedClickedAreas = clickedAreasTest.filter(e => e !== areaId);
            this.setState({ clickedAreas: updatedClickedAreas });
            return;
        }
        console.log('Before add: ', clickedAreasTest);
        this.setState(prevState => ({ clickedAreas: [...prevState.clickedAreas, areaId] }));
    };

    componentDidUpdate() {
        console.log('USeEffect : ', this.state.clickedAreas);
    }

    render() {
        return (
            <>
                <View
                    listImagesMapper={this.state.listImagesMapper}
                    onAreaClick={this.onAreaClick}
                    clickedAreas={this.state.clickedAreas}
                ></View>
            </>
        );
    }
}

export default Page;
