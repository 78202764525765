import {QuestionFooter, IProps as IFooterProps} from "../../components/question-footer/QuestionFooter";
import {Service} from "../../models/Service";
import "./ServiceStyles.scss"

interface IProps {
    services: Service[];
    selected?: Service;
    footer?: IFooterProps;
    onOptionClick: any;
}

export const ServiceView = (props: IProps) => {
    return (
        <div className={"page"}>
            <div className={"service"}>
                <div className={"service-content service-checkboxes"}>
                    <div className={"checkboxes"}>
                        {props.services.map(s => (
                          <div key={s.id} className={"checkbox"}>
                              <button
                                key={s.id}
                                className={s.id === props.selected?.id ? "active" : ""}
                                onClick={() => props.onOptionClick(s.id)}
                              >
                                  {s.name}
                              </button>
                          </div>
                        ))}
                    </div>
                </div>
                <QuestionFooter {...props.footer}/>
            </div>
        </div>
    )
}