import {BaseController} from "./BaseController";
import {Service} from "../models/Service";
import {ApiTaxonomy} from "../models/api/ApiTaxonomy";

export class ServiceController extends BaseController {
  getServices(): Promise<Service[]> {
    return this.get("jsonapi/taxonomy_term/services").then(res => {
      return(res as ApiTaxonomy).data.map(
        s => new Service(s.attributes.name, s.id, s.relationships.field_first_question.data.id)
      );
    })
  }
}