export class Constants {
    static KEY_LANGUAGE = "language";
    static NAME_LANGUAGE = "language-name";
    static KEY_ANSWERS = "answers";
    static KEY_START = "start";
    static KEY_FIRST_QUESTION = "first-question";
    static KEY_CATEGORY = "category";
    static KEY_COOKIE = "MARTI_ID";
    static TAXONOMY_TEXT_TO_TRANSLATE = "string_to_translate";
    static KEY_TRANSLATE_IDK = "translate-idk";
    static KEY_TRANSLATE_BUTTON_TEXT = "translate-button-text";
    static KEY_TRANSLATE_BACK_SURVEY = "translate-back-survey";
    static BLIND_MODE = "blind-mode";
}
