import "./Scan.scss";
import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {Constants} from "../../utils/Constants";

const Scan = () => {
    const [data, setData] = React.useState(null);

    const handleDataAvailability = () => {
        if(!data){
            return;
        }
        const service : any = localStorage.getItem('SERVICE_SELECTED');
        if(service){
            localStorage.setItem("NUMERO_PATIENT", data);
            window.location.href = JSON.parse(service).name;
        }
        else {
            window.location.href = "/";
        }

        // Your logic to check for data availability here
        // For example, if data is available, setData(data) should be called
        // If data is not available, setData(null) or do nothing
    };
    const handleInputChange = (e : any) => {
        // Update the data state with the new input value
        setData(e.target.value);
    };
  return (
      <div className="scan">
        <div>
          <h2>Scan patient</h2>
            <div style={{width: '100%'}}>
                <div style={{marginBottom : "20px"}}>
                    Merci de scanner le code-barre des étiquettes qui vous ont été remises, en autorisant l'accès à appareil photo :
                </div>
                <BarcodeScannerComponent
                    width={500}
                    onUpdate={(err : any, result : any) => {
                        console.log(result)
                        if (result) setData(result.text);
                    }}
                />
            </div>


            <div style={{marginTop: "30px"}}>Si le numéro n'est pas détecté, merci de le saisir ici :
                <input type={"text"} value={data ? data : ""}    onChange={handleInputChange} />
            </div>

            <div style={{marginTop: "30px"}}>
                <button
                    className={`btn${!data ? " btn-disabled" : ""}`}
                    onClick={handleDataAvailability}
                    disabled={!data} // Disable the button when data is not available
                >Valider</button>

            </div>
        </div>
      </div>
  );
};

export default Scan;
