const compareStrings = (str1: string, str2: string): boolean => {
    // Decode, normalise and lowercase string
    str1 = decodeURIComponent(str1).substring(1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
    str2 = decodeURIComponent(str2).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim();
    // Compare
    if (str1 === str2) {
        return true;
    } else {
        return false;
    }
}

export { compareStrings };