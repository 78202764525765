import {Component} from "react";
import {Question} from "../../../models/Question";
import {QuestionNumberView} from "./QuestionNumberView";
import {isNumber} from "util";
import {Interval} from "../../../models/Interval";

interface IProps {
    question: Question;
    setQuestion: any;
}

export class QuestionNumberPage extends Component<IProps> {
    render() {
        return <QuestionNumberView question={this.props.question}
                                   onMinusButtonClick={this.onMinusButtonClick.bind(this)}
                                   onPlusButtonClick={this.onPlusButtonClick.bind(this)}/>
    }

    private onMinusButtonClick() {
        let question = this.props.question;
        let answer = question.answer;
        if (answer && answer.value && typeof answer.value === 'number' && answer.options) {
            if (answer.value > Math.min(...answer.options.map(o => (o as Interval).min))) {
                answer.value--;
                this.props.setQuestion(question);
            }
        }
    }

    private onPlusButtonClick() {
        let question = this.props.question;
        let answer = question.answer;
        if (answer && answer.value !== undefined && typeof answer.value === 'number' && answer.options !== undefined) {
            if (answer.value < Math.max(...answer.options.map(o => (o as Interval).max))) {
                answer.value++;
                this.props.setQuestion(question);
            }
        }
    }
}
