import { useState, useEffect } from 'react';
import './ProgressBar.scss';

export interface IProps {
    questionsCompletedCount: number;
    show?: boolean
}

export const ProgressBar = (props: IProps) => {
    const [completedWidth, setCompletedWidth] = useState<number>(0);
    const [notCompletedWidth, setNotCompletedWidth] = useState<number>(0);
    const MAX_QUESTIONS_COUNT: number = 40;

    useEffect(() => {
        setCompletedWidth((props.questionsCompletedCount / MAX_QUESTIONS_COUNT) * 100);
        setNotCompletedWidth(100 - (props.questionsCompletedCount / MAX_QUESTIONS_COUNT) * 100);
    },
        [props.questionsCompletedCount]);

    return (
        props.show ?
            <div className={'progress-bar'}>
                <div className={'progress-bar-container'}>
                    <div className={'completed'} style={{ width: `${completedWidth}%` }}></div>
                    <div className={'normal'} style={{ width: `${notCompletedWidth}%` }}></div>
                </div>

            </div>
            : <></>
    );
}
