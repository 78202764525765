import "./MenuStyles.scss";
import {Constants} from "../../utils/Constants";

interface IProps {
    onMenuClick: any;
    onIdClick: any;
    onHomeClick: any;
    content: string;
}

export const MenuView = (props: IProps) => {
    return (
        <div className={"page"}>
            <div className={"menu"}>
                <div className={"menu-header"}>
                    <div>
                        Mentions légales
                    </div>
                    {/* <img src={process.env.PUBLIC_URL + "/pictos/roue.svg"} alt={"Fermer menu"} */}
                         {/* onClick={props.onMenuClick}/> */}
                </div>
                <div className={"menu-content"}>
                    <div dangerouslySetInnerHTML={{__html: props.content}}/>
                    <button onClick={props.onMenuClick}>{localStorage.getItem(Constants.KEY_TRANSLATE_BACK_SURVEY)}</button>
                </div>
                <div className={"menu-footer"}>
                    <button onClick={props.onHomeClick}>Retour à l'accueil</button>
                    <button onClick={props.onIdClick}>Réinitialiser ID Tablette</button>
                </div>
            </div>
        </div>
    )
}