import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {LanguagePage} from "./pages/language/LanguagePage";
import {QuestionPage} from "./pages/question/QuestionPage";
import Cookies from "universal-cookie";
import {Constants} from "./utils/Constants";
import {IdPage} from "./pages/id/IdPage";
import {LegalMentionsPage} from "./pages/legal-mentions/LegalMentionsPage";
import {ServicePage} from "./pages/service/ServicePage";
import GeneralConditions from './pages/general-conditions/GeneralConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import Page from './pages/ClickableImgTest/QuestionClickableImagePage';
import Scan from "./pages/Scan/Scan";

class Doc extends React.Component {
    componentDidMount() {
        document.title = "MARTI";
    }

    render() {
        // const cookies = new Cookies();
        // let id = cookies.get(Constants.KEY_COOKIE);

        // if (!id) {
        //     return <IdPage/>
        // } else {
		return (
			<React.StrictMode>
				<Router>
					<Switch>
						<Route exact path="/" component={() => <ServicePage/>} />
						<Route exact path="/language" component={() => <LanguagePage/>} />
						<Route exact path="/scan" component={() => <Scan/>}/>
						<Route exact path="/legal-mentions" component={() => <LegalMentionsPage/>}/>
						<Route exact path="/general-conditions" component={() => <GeneralConditions/>}/>
						<Route exact path="/privacy-policy" render={() => <PrivacyPolicy/>}/>
						<Route exact path="/test-1" render={() => <Page/>}/>
						<Route exact path="/:serviceName" component={() => <LanguagePage/>} />
						<Route path="/question/:id?" render={(props) => <QuestionPage {...props}/>}/>
					</Switch>
				</Router>
			</React.StrictMode>
		)
        //}
    }
}

ReactDOM.render(<Doc/>, document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
