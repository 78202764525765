import { Question } from "../../../models/Question";
import "./QuestionFreeTextStyle.scss";
import { Constants } from "../../../utils/Constants";
import { dataTraduction } from "../../../utils/DataTraduction";


interface IProps {
  question: Question;
  placeholder?: string;
  onChange: any;
}

export const QuestionFreeTextView = (props: IProps) => {
  const isBlindModeOn: string = localStorage.getItem(Constants.BLIND_MODE)!;
  let picture;
  let placeholder = dataTraduction.find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data.help[0];
  let text = dataTraduction.find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data.help[0];

  if (props.question.picture !== undefined && isBlindModeOn === 'false') {
    picture = (
      <div className={"question-picture"}>
        <img src={props.question.picture} alt={props.question.pictureAlt} />
      </div>
    )
  }

  return (
    <div className={"question-content question-free-text"}>
      {/*<i>*/}
      {/*  {text}*/}
      {/*</i>*/}
      {picture}

      <textarea
        autoFocus={true}
        onBlur={(e) => e.target.placeholder = props.placeholder ? props.placeholder : placeholder!}
        placeholder={"Merci d'écrire votre réponse ici"}
        onChange={props.onChange}
      />
    </div>
  );
}
