export class Service {
  name: string;
  id: string;
  firstQuestionId: string

  constructor(name: string, id: string, firstQuestionId: string) {
    this.name = name;
    this.id = id;
    this.firstQuestionId = firstQuestionId;
  }
}