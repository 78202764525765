import { Component } from "react";
import { Question } from "../../../models/Question";
import { QuestionCheckboxesView } from "./QuestionCheckboxesView";

interface IProps {
    question: Question;
    setQuestion: any;
    openOther: any;
}

export class QuestionCheckboxesPage extends Component<IProps> {
    render() {
        return <QuestionCheckboxesView question={this.props.question}
            onOptionClick={this.onOptionClick.bind(this)} />
    }

    private onOptionClick(id: string) {
        let question = this.props.question;
        let answer = question.answer;
        if (answer) {
            if (answer.multiValued) {
                let values: string | string[];
                if (!answer.value) {
                    values = [];
                } else if (Array.isArray(answer.value)) {
                    values = answer.value as string[];
                } else {
                    values = answer.value as string;
                }

                if (Array.isArray(values)) {
                    if (values.includes(id)) {
                        values.splice(values.indexOf(id, 0), 1);
                    } else {
                        values.push(id);
                    }
                } else { // case: Je ne sais pas
                    values = [];
                    values.push(id);
                }

                if (values.length === 0) {
                    answer.value = undefined; // Question next not be set when no answer selected in multicheckboxes 
                } else {
                    answer.value = values;
                }

            } else {
                if (answer.value !== id) {
                    answer.value = id;
                } else {
                    answer.value = undefined;
                }
            }

            if (answer.value && id === "other" && (answer.value as string | string[]).includes("other")) {
                this.props.openOther();
            }
            
            this.props.setQuestion(question);
        }
    }
}