import { useEffect, useState } from 'react';
import ImageMapper from "react-img-mapper";

import { ImageMapper as ImageMapperType } from './QuestionClickableImagePage';

interface ViewProps {
    imageMapper: ImageMapperType;
    onAreaClick: (areaId: string, clickedAreas: string[]) => void;
    clickedAreas: string[];
    initOptions?: any;
}

const QuestionClickableImageView = ({ imageMapper, onAreaClick, clickedAreas }: ViewProps) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);
    useEffect(() => {
        if (clickedAreas.length > 0) {
            imageMapper.map.areas.map(a => {
                if (clickedAreas.includes(a.next.concat('&&&', a.id))) {
                    a.preFillColor = '#590DE1';
                }
            })
        }
    }, [])

    useEffect(() => {
        imageMapper.map.areas.map(a => {
            if (clickedAreas.includes(a.next.concat('&&&', a.id))) {
                a.preFillColor = '#590DE1';
            } else {
                delete a.preFillColor;
            }
        })
    }, [isClicked])

    const handleAreaClick = (area: any) => {
        let newClickedAreas = [];
        if (clickedAreas.includes(area.next.concat('&&&', area.id))) {
            newClickedAreas = clickedAreas.filter(a => a != area.next.concat('&&&', area.id));
        } else {
            newClickedAreas = [...clickedAreas, area.next.concat('&&&', area.id)]
        }
        onAreaClick(area, newClickedAreas);
        setIsClicked(!isClicked);
    }

    return (
        < div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ImageMapper
                src={imageMapper.imageSrc}
                map={imageMapper.map}
                rerenderProps={["onClick"]}
                stayMultiHighlighted={true}
                toggleHighlighted={true}
                onClick={handleAreaClick} />
        </div >
    )


}
export default QuestionClickableImageView;
