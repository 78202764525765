import {LoadingComponent} from "../../components/loading/LoadingComponent";
import "./LoadingStyles.scss";

export const LoadingView = () => {
    return (
        <div className={"page loading-page"}>
            <div>
                <LoadingComponent size={200}/>
            </div>
        </div>
    )
}