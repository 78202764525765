import {Answer} from "./Answer";

export class Question {
    id: string;
    title: string;
    answer?: Answer;
    picture?: string;
    pictureAlt?: string;
    next?: string;

    constructor(id: string, title: string) {
        this.id = id;
        this.title = title;
    }
}