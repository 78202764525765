import getEnv from "../environment";

export abstract class BaseController {
    protected async get(url: string, parameters?: Map<string, string | boolean | number>): Promise<any> {
        return this.handleResponse(fetch(this.buildUrl(url, parameters), {method: 'GET'}));
    }

    protected async post(url: string, body?: any): Promise<any> {
        return this.handleResponse(fetch(this.buildUrl(url), {method: 'POST', body: JSON.stringify(body)}));
    }

    private buildUrl(url: string, parameters?: Map<string, string | boolean | number>) {
        if (url !== undefined && url !== '' && !url.includes(getEnv().apiUrl)) {
            url = getEnv().apiUrl + url;
        }

        if (parameters !== undefined && parameters.size > 0) {
            url += '?';
            let parametersArray: string[] = [];
            parameters.forEach((value, key) => {
                parametersArray.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
            });
            url += parametersArray.join('&');
        }

        return url;
    }

    private handleResponse(promise: Promise<Response>): Promise<any> {
        return promise
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType?.includes("json")) {
                    return response.text().then(value => value ? JSON.parse(value) : null);
                } else {
                    return response.blob();
                }
            })
    }
}