import { useRef } from "react";
import {Question} from "../../../models/Question";
import "./QuestionThermometerStyles.scss";
import {Interval} from "../../../models/Interval";

interface IProps {
    question: Question;
    onChange: any;
}

export const QuestionThermometerView = (props: IProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const btnTempStep: number = 0.5;

    let min: any;
    let max: any;
    let percent;

    if (props.question.answer?.options) {
        min = Math.min(...props.question.answer?.options?.map(o => (o as Interval).min));
        max = Math.max(...props.question.answer?.options?.map(o => (o as Interval).max));
        if (props.question.answer.value) {
            percent = 100 - (props.question.answer.value as number - min) / (max - min) * 100;
        }
    }

    const handleIncrement = () => {
        let newValue: number;
        if(inputRef.current) {
            newValue = parseFloat(inputRef.current.value) + btnTempStep;
            if (max && newValue <= max) {
              inputRef.current.value = newValue.toString();
              props.onChange(newValue);
            }
        }
      };

    const handleDecrement  = () => {
        let newValue: number;
        if(inputRef.current) {
            newValue = parseFloat(inputRef.current.value) - btnTempStep;
            if (min && newValue >= min) {
                inputRef.current.value = newValue.toString();
                props.onChange(newValue);
            }
        }
    };

    return (
        <div className={"question-content question-thermometer"}>
            <div className={"temperature"}>
                {props.question.answer?.value}°C
                <br/>
                {Math.round((props.question.answer?.value as number * 9/5 + 32) * 10) / 10}°F
            </div>
            <div className={"thermometer"}>
                <div className={"meter"}>
                    <div className={"background-container"}>
                        <div className={"background"} style={{width: percent !== undefined ? percent + "%" : "", visibility: percent !== 0 ? "visible" : "hidden"}}/>
                    </div>
                </div>
                <div className={"round-container"}>
                    <div className={"round"}/>
                </div>
                <input ref={inputRef}
                       type={"range"} min={min} max={max} step={0.1}
                       value={props.question.answer ? props.question.answer.value as number : min}
                       onChange={(e) => props.onChange(e.target.value)}/>
            </div>
            <div className={"temperature-btn"}>
                <div className={"btn"} onClick={handleIncrement}><img src={process.env.PUBLIC_URL + "/pictos/icon_plus.png"} alt={"PLus icon"}/></div>
                <div className={"btn"} onClick={handleDecrement}><img src={process.env.PUBLIC_URL + "/pictos/icon_moins.png"} alt={"Moins icon"}/></div>
            </div>
        </div>
    )
}