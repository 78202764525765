import {Component} from "react";
import {LegalMentionsView} from "./LegalMentionsView";
import {NewsController} from "../../controllers/NewsController";
import {News} from "../../models/News";
import {LoadingView} from "../loading/LoadingView";
import {Constants} from "../../utils/Constants";
import {LegalMentionsVeterinaireView} from "./LegalMentionsVeterinaireView";

interface IState {
    news: News[];
}

export class LegalMentionsPage extends Component<{}, IState> {
    newsController = new NewsController();

    componentDidMount() {
        this.newsController.getList().then(news => this.setState({news}));
    }

    render() {
        const service : any = localStorage.getItem('SERVICE_SELECTED');
        if (!this.state) {
            return <LoadingView/>
        } else {
            if(JSON.parse(service).name === 'Vétérinaire' ){
                return <LegalMentionsVeterinaireView title={this.state.news[0].title} content={""} onDeclineClick={this.onDeclineClick.bind(this)} onAcceptClick={this.onAcceptClick.bind(this)} />
            }
            else {
                return <LegalMentionsView title={this.state.news[0].title} content={this.state.news[0].content}  onDeclineClick={this.onDeclineClick.bind(this)} onAcceptClick={this.onAcceptClick.bind(this)} />

            }



        }
    }

    onDeclineClick() {
        window.location.href = "/";
    }

    onAcceptClick() {
        window.location.href = "/question/" + localStorage.getItem(Constants.KEY_FIRST_QUESTION);
    }
}
