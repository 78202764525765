import "./LegalMentionsStyles.scss";
import { Constants } from "../../utils/Constants";
// @ts-ignore
import Popup from "../popup/Popup";
import React, { useState } from 'react';
import { dataTraduction } from "../../utils/DataTraduction";

interface IProps {
    title: string;
    content: string;
    onDeclineClick: any;
    onAcceptClick: any;
}


export const LegalMentionsVeterinaireView = (props: IProps) => {

    const inputElRGPD = React.useRef<HTMLInputElement>(null);
    const inputElCGU = React.useRef<HTMLInputElement>(null);

    const [checkedRGPD, setCheckedRGPD] = useState(false);
    const [checkedCGU, setCheckedCGU] = useState(false);

    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const handlerOpenPopup = () => {
        setOpenPopup(!openPopup);
    };

    const onBtnClick = () => {
        !checkedCGU && inputElCGU.current?.focus();
        !checkedRGPD && inputElRGPD.current?.focus();
    };

    const handleChange = (typeChecked: string) => {
        typeChecked === "RGPD" ? setCheckedRGPD(!checkedRGPD) : setCheckedCGU(!checkedCGU);
    };

    return (
        <div className={"page"}>
            <div className={"lm"}>
                <div className={"lm-header"}>
                    {props.title}
                </div>
                <div className={"lm-content"} >
                    <div>

                        <p>La solution Veti a été conçue pour améliorer la prise en charge de votre animal.</p>

                        <p>Le logiciel va vous permettre de remplir des informations sur l’état de santé de votre animal dans la salle d’attente pour faciliter sa prise en charge par votre vétérinaire. Ce traitement est fondé sur votre consentement. Vous êtes libre de remplir ce questionnaire ou de ne pas le faire. Votre prise en charge ne sera pas modifiée si vous ne souhaitez pas remplir le questionnaire.</p>

                        <p>
                            Les données personnelles collectées sont les suivantes&nbsp;:<br/>
                            - Données relatives à votre et à celle de votre animal (nom, date de naissance)<br/>
                            - Données de santé : antécédents médicaux, symptômes, médicaments etc.
                        </p>

                        <p>Les données de votre animal sont accessibles au vétérinaire pendant votre consultation. Les données peuvent être intégrées au dossier patient informatisé de votre animal..</p>


                        <p>Vous avez des droits sur vos données. Vous disposez, selon les circonstances, d’un
                            droit d’accès, de portabilité, de rectification, de limitation, d’opposition et de
                            suppression sur les données de votre enfant.<br/>
                                Pour les exercer, vous pouvez écrire à l’adresse
                                suivante&nbsp;:&nbsp;dpo@martiapp.fr</p>

                        <p>Si vous estimez, après nous avoir contactés, que vos droits «&nbsp;Informatiques et
                            Libertés&nbsp;» ne sont pas respectés, vous pouvez adresser une réclamation à la
                            Commission nationale de l’informatique et des libertés (la «&nbsp;CNIL&nbsp;») sur
                            son site internet&nbsp;: https://www.cnil.fr.</p>

                        <p>&nbsp;</p>
                    </div>

                    <div className={`lm-checkbox`}>
                        <div style={{ display: 'flex', gap: '2%', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                checked={checkedRGPD}
                                onChange={() => handleChange("RGPD")}
                                ref={inputElRGPD}
                                style={{ minWidth: '20px' }}
                                // autoFocus
                                alt={"Accepter"} />
                            <div>
                                En répondant à ce questionnaire, je consens à la collecte et au traitement de données à caractère personnel relatives à la santé de mon animal.
                            </div>
                        </div>

                        <br />
                        <div style={{ display: 'flex', gap: '2%', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                checked={checkedCGU}
                                onChange={() => handleChange("CGU")}
                                ref={inputElCGU}
                                style={{ minWidth: '20px' }}
                                // autoFocus
                                alt={"Accepter"} />
                            <div>
                                En cochant cette case, je déclare avoir pris connaissance des conditions générales d'utilisation de l'outil et les accepter.
                                <div style={{ textDecoration: 'underline', color: '#3366CC' }} onClick={handlerOpenPopup}>(lien hypertexte)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"lm-footer"}>
                    <img src={process.env.PUBLIC_URL + "/pictos/cross-select.svg"} onClick={props.onDeclineClick} alt={"Décliner"} />
                    <img src={process.env.PUBLIC_URL + "/pictos/v-select.svg"} onClick={checkedRGPD && checkedCGU ? props.onAcceptClick : onBtnClick} alt={"Accepter"} />
                </div>
            </div>
            {openPopup && <Popup closePopup={handlerOpenPopup}></Popup>}
        </div>
    )
}
