import { Component } from "react";
import { Question } from "../../../models/Question";
import { QuestionYesNoView } from "./QuestionYesNoView";

interface IProps {
    question: Question;
    setQuestion: any;
}

export class QuestionYesNoPage extends Component<IProps> {
    componentDidMount(): void {
        // console.log(this.props.question);
    }
    render() {
        return <QuestionYesNoView question={this.props.question} onOptionClick={this.onOptionClick.bind(this)} />
    }

    private onOptionClick(id: string) {
        let question = this.props.question;
        if (question.answer) {
            if (question.answer.value !== id) {
                question.answer.value = id;
            }

            this.props.setQuestion(question);
        }
    }
}