import "./QuestionOtherStyles.scss";
import { Constants } from "../../../utils/Constants";
import { dataTraduction } from "../../../utils/DataTraduction";

interface IProps {
    value?: string;
    placeholder?: string;
    onChange: any;
    close: any;
}

export const QuestionOtherView = (props: IProps) => {
    let placeholder = dataTraduction.find(l => l.key === localStorage.getItem(Constants.KEY_LANGUAGE))?.data.placeholder[0];

    return (
        <div className={"question-content question-other"}>
            <textarea
                autoFocus = {true}
                // onFocus={(e) => e.target.placeholder = ""}
                onBlur={(e) => e.target.placeholder = props.placeholder ? props.placeholder : placeholder!}
                placeholder={props.placeholder ? props.placeholder : placeholder!} onChange={props.onChange}/>
            <img src={process.env.PUBLIC_URL + "/pictos/fleche.svg"} alt={"Fermer"} onClick={props.close}/>
        </div>
    )
}